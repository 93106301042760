function formatUSAPhone(phoneNumber: string) {
    if (!phoneNumber) return;
    if (typeof phoneNumber !== 'string') {
        throw new Error('The phone number must be provided as a string.');
    }

    // Check if the number starts with the plus sign
    if (!phoneNumber.startsWith('+')) {
        throw new Error("The phone number must start with a '+' sign.");
    }

    // Strip out any non-digit characters (except the initial '+')
    const cleanedNumber = phoneNumber.replace(/[^+\d]/g, '');

    // Validate the cleaned number format
    if (!cleanedNumber.startsWith('+1')) {
        throw new Error("The phone number must be a US number starting with '+1'.");
    }

    // Extract the country code, area code, and remaining number parts
    const areaCode = cleanedNumber.substring(2, 5);
    const firstPart = cleanedNumber.substring(5, 8);
    const secondPart = cleanedNumber.substring(8);

    // Format and return the formatted phone number
    return `(${areaCode}) ${firstPart}-${secondPart}`;
}

export default formatUSAPhone;
