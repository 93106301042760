import React, { useState } from 'react';

// components
import { Button, Input, Modal, Col, Row, Select } from 'antd';
import Label from 'components/label';

// helpers
import notify from 'utils/notification';

// assets
import { SaveLogo } from 'assets/svg';

interface IProps {
    visible: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
}

const InviteUser: React.FC<IProps> = ({ visible, onSave, onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');

    const handleSaveClick = () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!emailRegex.test(email)) {
            notify({ type: 'error', message: 'Please enter a valid email' });
            return;
        }

        if (!name || !email || !role) {
            notify({ type: 'error', message: 'Please fill in name, email and role fields' });
            return;
        }

        if (onSave) {
            onSave({ name, email, role });
        }
    };

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            title={'Invite User'}
            footer={[
                <Button className="flex-1" key="submit" type="primary" onClick={handleSaveClick} icon={<SaveLogo />}>
                    Save
                </Button>,
            ]}
        >
            <Row className="mt-4" gutter={16}>
                <Col span={24}>
                    <Label>Name</Label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full mb-4"
                        placeholder="Name"
                    />
                    <Label>Email</Label>
                    <Input
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full mb-4"
                        placeholder="Email"
                    />
                    <Label>Role</Label>
                    <Select
                        value={role}
                        onChange={(value) => setRole(value)}
                        options={[
                            { value: 'user', label: 'User' },
                            { value: 'owner', label: 'Owner' },
                        ]}
                        className="w-full mb-4"
                        placeholder="Select a role"
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default InviteUser;
