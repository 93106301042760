import React from 'react';

interface IProps {
    children: React.ReactNode;
    className?: string;
}

const Card: React.FC<IProps> = ({ children, className }) => {
    return (
        <div className={`border rounded-10 border-solid border-greyscale-200 p-6 bg-white ${className}`}>
            {children}
        </div>
    );
};

export default Card;
