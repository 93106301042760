import { createElement } from 'react';

import { icons } from './Icons';

export type IconName = keyof typeof icons;

interface Props {
    icon: IconName;
    height: string;
    width: string;
    className?: Array<string>;
}

export const Icon = ({ icon, height, width, className = [] }: Props) => {
    const iconClasses = new Set(className);

    return createElement(icons[icon], {
        height,
        width,
        className: Array.from(iconClasses).join(' '),
    });
};
