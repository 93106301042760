import React from 'react';

// components
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

interface SpinnerProps {
    visible: boolean;
    className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ visible, className }) =>
    visible ? (
        <Spin
            className={`fixed top-0 left-0 z-[9999] w-full h-full bg-white bg-opacity-50 flex justify-center items-center ${className}`}
            spinning={visible}
            indicator={<LoadingOutlined className="text-5xl" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
        />
    ) : null;

export default Spinner;
