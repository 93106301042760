import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import BuckThemeProvider from '@buck-ai/buck-component';
import { AppProvider } from 'context';
import { ConfigProvider } from 'antd';
import { modalClassNames } from 'styles';

// icons
import { CloseIcon } from 'assets/svg';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <AppProvider>
            <BuckThemeProvider>
                <ConfigProvider
                    modal={{
                        classNames: modalClassNames,
                        closeIcon: <CloseIcon />,
                    }}
                >
                    <App />
                </ConfigProvider>
            </BuckThemeProvider>
        </AppProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
