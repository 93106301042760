import { HighPriority, LowPriority, NormalPriority, UrgentPriority } from 'assets/svg';

export enum Role {
    ADMIN = 'admin',
    USER = 'user',
    OWNER = 'owner',
}

export enum Source {
    CUSTOMER = 'customer',
    SYSTEM = 'system',
}

export enum Priority {
    HIGH = 'high',
    NORMAL = 'normal',
    LOW = 'low',
    URGENT = 'urgent',
}

export enum PriorityLabel {
    high = 'High',
    normal = 'Normal',
    low = 'Low',
    urgent = 'Urgent',
}

export enum Status {
    AWAITING_AGENT = 'awaiting_agent',
    COMPLETED = 'completed',
    REJECTED = 'rejected',
    CLOSED = 'closed',
    ERROR = 'error',
    INACTIVE = 'inactive',
    IN_CONVERSATION = 'in_conversation',
}

export enum ContactSource {
    PHONE = 'phone',
    EMAIL = 'email',
}

export enum StatusLabel {
    awaiting_agent = 'Awaiting Agent',
    completed = 'Completed',
    rejected = 'Rejected',
    closed = 'Closed',
    error = 'Error',
    inactive = 'Inactive',
    in_conversation = 'In Conversation',
}

export const ConversationStatusLabels: Record<
    string,
    { label: string; tagClass: string; barClass: string; tooltipTitle: string }
> = {
    awaiting_agent: {
        label: 'Awaiting Agent',
        tagClass: 'bg-orange-50 text-orange',
        barClass: 'bg-orange',
        tooltipTitle: 'Conversation with Ai is on hold',
    },
    completed: {
        label: 'Completed',
        tagClass: 'bg-green-50 text-green-600',
        barClass: 'bg-green-600',
        tooltipTitle: 'Conversation with Ai completed',
    },
    rejected: {
        label: 'Rejected',
        tagClass: 'bg-red-50 text-red-600',
        barClass: 'bg-red-600',
        tooltipTitle: 'Rejected conversation with Ai',
    },
    closed: {
        label: 'Closed',
        tagClass: 'bg-greyscale-50 text-greyscale-800',
        barClass: 'bg-greyscale-800',
        tooltipTitle: 'Conversation with Ai is closed',
    },
    error: {
        label: 'Error',
        tagClass: 'bg-red-50 text-red-600',
        barClass: 'bg-red-600',
        tooltipTitle: 'Error talking to Ai',
    },
    inactive: {
        label: 'Inactive',
        tagClass: 'bg-greyscale-50 text-greyscale-500',
        barClass: 'bg-greyscale-500',
        tooltipTitle: 'Conversation with Ai is not active',
    },
    in_conversation: {
        label: 'In Conversation',
        tagClass: 'bg-blue-50 text-blue-500',
        barClass: 'bg-blue-500',
        tooltipTitle: 'Conversation with Ai continues',
    },
};

export const ConversationReasonLabels: Record<string, string> = {
    booking_reschedule: 'Reschedule',
    booking_request: 'Booking Request',
    booking_cancel: 'Cancel',
    status_check: 'Status Check',
    other: 'Other',
    report_complaint: 'Report complaint',
};

export const ConversationTypeLabels: Record<string, string> = {
    sms: 'SMS',
    web_chat: 'Web Chat',
};

export enum Reason {
    APPOINTMENT_RESCHEDULE = 'appointment_reschedule',
    BOOKING_REQUEST = 'booking_request',
    APPOINTMENT_CANCEL = 'appointment_cancel',
    REPORT_COMPLAINT = 'report_complaint',
    STATUS_CHECK = 'status_check',
    OTHER = 'other',
}

export enum ReasonLabel {
    appointment_reschedule = 'Appointment Reschedule',
    booking_request = 'Booking Request',
    booking_reschedule = 'Booking Reschedule',
    booking_cancel = 'Booking Cancel',
    appointment_cancel = 'Appointment Cancel',
    report_complaint = 'Report Complaint',
    status_check = 'Status Check',
    other = 'Other',
    booking = 'booking',
}

export enum IntegrationStatus {
    active = 'active',
    pending = 'pending',
    passive = 'passive',
}

export const PriorityLabelWithColorAndIcon: Record<string, { label: string; textClass: string; icon: any }> = {
    urgent: { label: 'Urgent', textClass: 'text-red-900', icon: UrgentPriority },
    normal: { label: 'Normal', textClass: 'text-primary-400', icon: NormalPriority },
    high: { label: 'High', textClass: 'text-amber-600', icon: HighPriority },
    low: { label: 'Low', textClass: 'text-green-600', icon: LowPriority },
};

export const IntegrationAppNames = {
    slack: 'Slack',
    v3: 'V3',
    phone: 'Phone',
    webChat: 'WebChat',
};

export const ModalTypes = {
    add: 'add',
    edit: 'edit',
};
