import React, { useContext, useEffect, useState } from 'react';
import { isValidToken, setSession } from './jwt';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'context';
import { jwtDecode } from 'jwt-decode';

interface ProtectedRouteProps {
    children: React.ReactNode;
}

interface DecodedJwt {
    exp: number;
    user_id: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    const navigate = useNavigate();
    const { getUser } = useContext(AuthContext);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkUserToken = () => {
        const accessToken = localStorage.getItem('accessToken') || null;
        if (accessToken && isValidToken(accessToken)) {
            const decoded: DecodedJwt = jwtDecode(accessToken);

            setSession(accessToken);
            getUser({ id: decoded.user_id });
            setIsLoggedIn(true);
        } else {
            setSession(null);
            setIsLoggedIn(false);
            return navigate('/login');
        }
    };

    useEffect(() => {
        checkUserToken();
    }, []);

    return <>{isLoggedIn ? props.children : null}</>;
};

export default ProtectedRoute;
