import React, { useEffect, useState } from 'react';

// components
import { Button, Card, Input, Col, Row, Typography, Skeleton, Divider, Tooltip } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import ConversationCard from 'components/conversation-card';
import { SearchLogo, FilterLogo, AdvancedFilterLogo, RightArrowLogo } from 'assets/svg';
import Spinner from 'components/spinner';
import Empty from 'components/empty';
import Thread from 'components/thread';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import useQuery from 'utils/useQuery';
// import { Status } from 'utils/constants';

interface IMessages {
    id: string;
    source: string;
    content: string;
    status: string;
    created_at: string;
    updated_at: string;
}

interface ICustomer {
    in_conversations_count: number | undefined;
    awaiting_agents_count: number | undefined;
    id: string;
    name: string;
    conversations_count: number | null;
    bookings_count: number | null;
    last_activity: string;
}

export interface IConversation {
    id: string;
    messages_count: number | null;
    last_sender: string;
    priority: string;
    reason: string;
    source: string;
    status: string;
    created_at: string;
    updated_at: string;
    last_message_saved_at: string;
    job_type: string;
    confirmed_time: string;
    customer: {
        id: string;
        name: string;
        conversations_count: number | null;
        bookings_count: number | null;
    };
    contact: {
        id: string;
        source: string;
        value: string;
    };
    booking: {
        id: string;
        status: string;
        job_type: string;
        confirmed_time: string;
        location_full_address: string;
    };
}

const { Title } = Typography;

export default function Conversations() {
    const [customerLoading, setCustomerLoading] = useState(false);
    const [conversationLoading, setConversationLoading] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [customerPage, setCustomerPage] = useState(1);
    const [conversationPage, setConversationPage] = useState(1);
    const [totalCustomerCount, setTotalCustomerCount] = useState<number>(0);
    const [totalConversationCount, setTotalConversationCount] = useState<number>(0);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>({
        id: '',
        name: '',
        conversations_count: null,
        bookings_count: null,
        last_activity: '',
        in_conversations_count: undefined,
        awaiting_agents_count: undefined,
    });
    const [conversations, setConversations] = useState<IConversation[]>([]);
    const [selectedConversationID, setSelectedConversationID] = useState<string | undefined>(undefined);
    const [messages, setMessages] = useState<IMessages[]>([]);
    const [totalMessageCount, setTotalMessageCount] = useState<number>(0);
    const [messagePage, setMessagePage] = useState<number>(1);

    const customer_id = useQuery('customer_id');
    const conversation_id = useQuery('conversation_id');

    const getCustomers = async (firstRenderCallback?: (customers: ICustomer[]) => void) => {
        setCustomerLoading(true);
        try {
            const res = await axiosClient.get(`${apiURL.conversationCustomers}?page=${customerPage}`);
            const fetchedCustomers = res.data.data;
            const total = res?.data?.meta?.total;

            setTotalCustomerCount(total);
            // to render first conversation of first customer(to choose first customer after get data)
            if (firstRenderCallback) {
                firstRenderCallback(res?.data?.data);
            } else {
                // Filter out any specific customer fetched via URL
                const filteredCustomers = fetchedCustomers.filter((c: ICustomer) => c.id !== customer_id);
                setCustomers((prevItems) => [...prevItems, ...filteredCustomers]);
            }

            if (res.data.data.length > 0 && customers.length + res.data.data.length < res.data.meta.total) {
                setCustomerPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customers' });
            console.error('error fetching customers', error);
        } finally {
            setCustomerLoading(false);
        }
    };

    const getConversations = async (customerId: string, fromCustomerClick: boolean) => {
        setConversationLoading(true);
        try {
            const res = await axiosClient.get(
                `${apiURL.customers}/${customerId}/conversations?page=${fromCustomerClick ? 1 : conversationPage}`,
            );
            const fetchedConversations = res.data.data;
            const total = res?.data?.meta?.total;

            setTotalConversationCount(total);
            // reset conversations in every customer click
            if (fromCustomerClick) {
                // select first conversation of every customer at first render and every customer click
                if (customer_id && conversation_id) {
                    const existingConversation = fetchedConversations.find(
                        (c: IConversation) => c.id === conversation_id,
                    );

                    if (existingConversation) {
                        // eğer ilk sayfada varsa git ona tıklat
                        setConversations(fetchedConversations);
                        handleConversationClick(res?.data?.data?.[0]?.id);
                    } else {
                        // eğer ilk sayfada yoksa git bu conversationı al
                        getSpecificConversation(fetchedConversations);
                    }
                } else {
                    handleConversationClick(res?.data?.data?.[0]?.id);
                    setConversations(fetchedConversations);
                }
            } else {
                // from infinite scroll
                const filteredConversations = fetchedConversations.filter(
                    (c: IConversation) => c.id !== conversation_id,
                );
                setConversations((prevItems) => [...prevItems, ...filteredConversations]);
            }

            if (res.data.data.length > 0 && conversations.length < res.data.meta.total) {
                setConversationPage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customers' });
            console.error('error fetching customers', error);
        } finally {
            setConversationLoading(false);
        }
    };

    const getMessages = async (conversationId: string, fromConversationClick: boolean) => {
        setMessageLoading(true);

        try {
            const res = await axiosClient.get(
                `${apiURL.conversations}/${conversationId}/messages?page=${fromConversationClick ? 1 : messagePage}`,
            );

            setTotalMessageCount(res?.data?.meta?.total);
            // reset messages in every conversation click
            if (fromConversationClick) {
                setMessages([...res.data.data] || []);
            } else {
                // from infinite scroll
                setMessages((prevItems) => [...prevItems, ...res.data.data]);
            }

            if (res.data.data.length > 0 && messages.length < res.data.meta.total) {
                setMessagePage((prevPage) => prevPage + 1);
            }
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching messages' });
            console.error('error fetching messages', error);
        } finally {
            setMessageLoading(false);
        }
    };

    const handleCustomerClick = (customer: ICustomer) => {
        setSelectedCustomer(customer);

        // reset conversationPage
        setConversationPage(1);
        getConversations(customer.id, true);
    };

    const handleConversationClick = async (id: string) => {
        setSelectedConversationID(id);

        // reset messagePage on every conversation click
        setMessagePage(1);
        getMessages(id, true);
    };

    const getSpecificCustomer = async (firstCustomers: any) => {
        setCustomerLoading(true);
        try {
            const res = await axiosClient.get(`${apiURL.customers}/${customer_id}`);
            setCustomers(() => [res.data, ...firstCustomers]);
            handleCustomerClick(res.data);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customer' });
        } finally {
            setCustomerLoading(false);
        }
    };

    const getSpecificConversation = async (firstConversations: any) => {
        setConversationLoading(true);
        try {
            const res = await axiosClient.get(`${apiURL.conversations}/${conversation_id}`);
            setConversations(() => [res.data, ...firstConversations]);
            handleConversationClick(res.data.id);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customer' });
        } finally {
            setCustomerLoading(false);
        }
    };

    useEffect(() => {
        getCustomers((customers: ICustomer[]) => {
            if (customers.length) {
                if (customer_id && conversation_id) {
                    const existingCustomer = customers.find((c) => c.id === customer_id);

                    if (existingCustomer) {
                        // eğer ilk sayfada varsa git ona tıklat ve ilk customers bu olsun
                        setCustomers(customers);
                        handleCustomerClick(existingCustomer);
                    } else {
                        // eğer ilk sayfada yoksa git bu customerı al
                        getSpecificCustomer(customers);
                    }
                } else {
                    // to render first conversation of first customer
                    setCustomers(customers);
                    handleCustomerClick(customers[0]);
                }
            }
        });
    }, []);

    useEffect(() => {
        if (customers.length) {
            setSelectedCustomer(customers[0]);
        }
    }, [customers.length]);

    return (
        <div className="flex flex-col flex-1">
            <Card bordered={false}>
                <div className="flex justify-between">
                    <Input className="max-w-sm" placeholder="Search" prefix={<SearchLogo className="w-4" />} />
                    <div className="flex">
                        <Button className="flex items-center mr-3">
                            <FilterLogo className="mr-2" />
                            Customer
                        </Button>
                        <Button className="flex items-center">
                            <AdvancedFilterLogo />
                        </Button>
                    </div>
                </div>
            </Card>
            <Row className="flex flex-1 mt-6" gutter={8}>
                {/* customers */}
                <Col sm={24} xl={12}>
                    <Card styles={{ body: { height: '100%', padding: 0 } }} className="h-full p-0" bordered={false}>
                        <div className="flex h-full">
                            {/* customers */}
                            <div className="flex-[1_1_0%] min-w-52 border-solid border-y-0 border-l-0 border-greyscale-100">
                                <div className="p-6 border-solid border-t-0 border-x-0 border-b-greyscale-100">
                                    <Title className="m-0" level={5}>
                                        Customers
                                    </Title>
                                    {/* Customers */}
                                </div>
                                <div
                                    style={{ height: '67vh', overflowY: 'auto' }}
                                    className="relative"
                                    id="scrollableDiv"
                                >
                                    <InfiniteScroll
                                        dataLength={customers.length}
                                        next={getCustomers}
                                        hasMore={customers.length < totalCustomerCount}
                                        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                        endMessage={<Divider plain>No more data to load</Divider>}
                                        scrollableTarget="scrollableDiv"
                                    >
                                        {customers?.map((el, i) => {
                                            const initials =
                                                (el?.name?.charAt(0)?.toUpperCase() || '') +
                                                (el?.name?.split(' ')[1]?.charAt(0)?.toUpperCase() || '');

                                            const awaitingAgentCount = el?.awaiting_agents_count;
                                            const inConversationCount = el?.in_conversations_count;
                                            return (
                                                <div
                                                    key={`${el.id}-${i}`}
                                                    onClick={() => handleCustomerClick(el)}
                                                    className={`flex items-center justify-between p-6 border-x-0 border-t-0 border-solid border-greyscale-100 hover:bg-amber-100 cursor-pointer ${
                                                        selectedCustomer?.id === el?.id
                                                            ? 'bg-amber-100 border-solid border-y-0 border-r-0 border-l-4 border-l-primary'
                                                            : ''
                                                    }`}
                                                >
                                                    <div className="flex items-center">
                                                        <div className="mr-2">
                                                            {awaitingAgentCount && awaitingAgentCount > 0 ? (
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={`${awaitingAgentCount} awaiting agent`}
                                                                >
                                                                    <div className="w-1.5 h-1.5 bg-orange-100 mb-1 rounded-full" />
                                                                </Tooltip>
                                                            ) : null}
                                                            {inConversationCount && inConversationCount > 0 ? (
                                                                <Tooltip
                                                                    placement="right"
                                                                    title={`${inConversationCount} in conversation`}
                                                                >
                                                                    <div className="w-1.5 h-1.5 bg-blue-500 rounded-full" />
                                                                </Tooltip>
                                                            ) : null}
                                                        </div>
                                                        <div className="w-6 h-6 mr-3 rounded-full bg-primary-300 flex justify-center items-center">
                                                            <p className="text-[0.625rem] text-white font-bold">
                                                                {initials}
                                                            </p>
                                                        </div>
                                                        <div className="text-greyscale-900 text-sm">{el?.name}</div>
                                                    </div>
                                                    <div className="">
                                                        {selectedCustomer?.id === el.id && <RightArrowLogo />}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </InfiniteScroll>
                                    <Spinner className="absolute" visible={customerLoading} />
                                </div>
                            </div>
                            {/* conversations */}
                            <div className="flex flex-col flex-[3_3_0%]">
                                <div className="p-6 flex items-center border-solid border-t-0 border-x-0 border-b-greyscale-100">
                                    <Title className="m-0" level={5}>
                                        Conversations
                                    </Title>
                                    <div className="text-base leading-6 text-greyscale-900 ml-2">
                                        ({totalConversationCount})
                                    </div>
                                </div>
                                <div className="flex flex-col flex-1">
                                    <div className="h-full relative overflow-scroll">
                                        <div
                                            id="scrollableDiv2"
                                            style={{ height: '67vh', overflowY: 'auto' }}
                                            className="absolute flex flex-col w-full h-full"
                                        >
                                            {conversations?.length > 0 && selectedCustomer?.id ? (
                                                <InfiniteScroll
                                                    dataLength={conversations.length}
                                                    next={() => getConversations(selectedCustomer.id, false)}
                                                    hasMore={conversations.length < totalConversationCount}
                                                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                                                    endMessage={<Divider plain>No more data to load</Divider>}
                                                    scrollableTarget="scrollableDiv2"
                                                >
                                                    {conversations?.map((conversation, index) => {
                                                        return (
                                                            <ConversationCard
                                                                key={index}
                                                                conversation={conversation}
                                                                selectedConversationID={selectedConversationID}
                                                                onClick={handleConversationClick}
                                                            />
                                                        );
                                                    })}
                                                </InfiniteScroll>
                                            ) : (
                                                <Empty
                                                    title="No showing conversation"
                                                    description="Please select a customer to display here."
                                                />
                                            )}
                                        </div>
                                        <Spinner className="absolute" visible={conversationLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                {/* thread */}
                <Col sm={24} xl={12}>
                    <Card styles={{ body: { height: '100%', padding: 0 } }} className="h-full p-0" bordered={false}>
                        <div className="flex flex-col h-full">
                            <div className="p-6 border-solid border-t-0 border-x-0 border-b-greyscale-100">
                                <Title className="m-0" level={5}>
                                    Thread
                                </Title>
                            </div>
                            <div className="p-6 flex flex-col flex-1 relative">
                                {selectedConversationID && messages?.length > 0 ? (
                                    <Thread
                                        customer={selectedCustomer}
                                        messages={messages}
                                        totalMessageCount={totalMessageCount}
                                        getMessages={() => getMessages(selectedConversationID, false)}
                                    />
                                ) : (
                                    <Empty
                                        title="No thread selected"
                                        description="Please select a conversation to display here."
                                    />
                                )}
                                <Spinner className="absolute" visible={messageLoading} />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
