import React from 'react';
import { Card } from 'antd';
import { StatisticUpArrow, StatisticDownArrow, GreenLineChart, RedLineChart, GreyLineChart } from 'assets/svg';

const InsightCard = ({ title, total, rate }: { title: string; total: number; rate: number }) => {
    const getRateClass = (rate: number) => {
        if (rate > 0) return 'text-green-600';
        if (rate === 0) return 'text-greyscale-500';
        return 'text-red-600';
    };

    const getRateIcon = (rate: number) => {
        if (rate > 0) return <StatisticUpArrow className="ml-1" />;
        if (rate === 0) return '-';
        return <StatisticDownArrow className="ml-1" />;
    };

    const getLineChart = (rate: number) => {
        if (rate > 0) return <GreenLineChart />;
        if (rate === 0) return <GreyLineChart />;
        return <RedLineChart />;
    };

    return (
        <Card styles={{ body: { height: '100%', padding: 0 } }} className="h-full p-6">
            <div className="flex justify-between">
                <div>
                    <div className="text-primary-900 text-lg font-bold mb-3">{title}</div>
                    <div className="flex items-center">
                        <div className="mr-3 text-primary-900 text-lg font-bold text-[44px]">{total}</div>
                        <div className={`${getRateClass(rate)} text-sm font-medium`}>
                            {rate}%{getRateIcon(rate)}
                        </div>
                    </div>
                    <div className="inline-block cursor-pointer py-2 px-4 border border-solid border-primary text-primary text-xs font-bold rounded-lg mt-5">
                        See All
                    </div>
                </div>
                <div className="ml-4">{getLineChart(rate)}</div>
            </div>
        </Card>
    );
};

export default InsightCard;
