import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

// components
import { Typography, Dropdown, Divider, Input } from 'antd';
import type { MenuProps } from 'antd';
import { apiURL, axiosClient } from 'service';
import notify from 'utils/notification';
import { jwtDecode } from 'jwt-decode';

// icons
import { DownArrowLogo, LogoutLogo, SettingsLogo, BriefCase } from 'assets/svg';

// context
import { AuthContext } from 'context';

interface DecodedJwt {
    exp: number;
    user_id: string;
}

const { Text } = Typography;

const Navbar = () => {
    const navigate = useNavigate();
    const {
        state: { user },
    } = useContext(AuthContext);

    const [accountName, setAccountName] = useState('');
    const [, setIsLoading] = useState(false);

    const active_membership = user?.memberships?.find((item: any) => item.account.id === user.active_account_id);

    const role =
        user?.role === 'admin' ? 'Admin' : active_membership?.role === 'user' ? 'Company Member' : 'Company Owner';

    const handleSwitchAccount = async (accountId: string) => {
        setIsLoading(true);
        try {
            const accessToken = localStorage.getItem('accessToken') || null;
            if (accessToken) {
                const decoded: DecodedJwt = jwtDecode(accessToken);

                await axiosClient.put(`${apiURL.users}/${decoded.user_id}`, {
                    user: {
                        active_account_id: accountId,
                    },
                });
            }

            notify({ type: 'success', message: 'Account changed successfully' });
            window.location.reload();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            notify({ type: 'error', message: error?.toString() });
        }
    };

    const filteredMemberShips = user?.memberships?.filter((item: any) => item.account_id !== user.active_account_id);

    const items: MenuProps['items'] = [
        {
            key: '1',
            disabled: true,
            label: (
                <div className="w-[245px] flex flex-col bg-greyscale-50 rounded-lg border border-solid border-greyscale-300">
                    <div className="flex account-name-container mt-6">
                        <BriefCase className="mt-[2px]" />
                        <div className="ml-2 flex flex-col">
                            <Text className="text-greyscale-900 text-sm">{user?.name}</Text>
                            <Text className="text-xs text-gray-500">
                                {active_membership?.account?.name} - {role}
                            </Text>
                        </div>
                    </div>
                    {filteredMemberShips?.length ? (
                        <>
                            <Divider className="my-0" />
                            <Input
                                className="my-6 mx-3 w-[221px]"
                                placeholder="Search an account"
                                prefix={
                                    <SearchOutlined
                                        onPointerEnterCapture={undefined}
                                        onPointerLeaveCapture={undefined}
                                    />
                                }
                                value={accountName}
                                onChange={(event) => setAccountName(event.target.value)}
                            />
                            {filteredMemberShips
                                ?.filter((item: any) =>
                                    item?.account?.name.toLowerCase().includes(accountName.toLowerCase()),
                                )
                                .map((membership: any, index: number) => {
                                    return (
                                        <>
                                            <div
                                                onClick={() => handleSwitchAccount(membership?.account_id)}
                                                className="account-name-container flex cursor-pointer"
                                            >
                                                <BriefCase className="mt-[2px]" />
                                                <div className="ml-2">
                                                    <div className="text-greyscale-900 text-sm">
                                                        {membership?.account?.name}
                                                    </div>
                                                    <div className="w-fit mt-3 py-1 px-2.5 rounded-lg border border-solid border-greyscale-500 text-greyscale-700 text-xs font-medium">
                                                        {membership?.role}
                                                    </div>
                                                </div>
                                            </div>
                                            {index !== filteredMemberShips?.length - 1 && (
                                                <Divider className="mb-6 mt-0" />
                                            )}
                                        </>
                                    );
                                })}
                        </>
                    ) : null}
                </div>
            ),
        },
        {
            key: '2',
            label: (
                <>
                    <Divider className="mt-6 mb-6" />
                    <div className="flex items-center">
                        <SettingsLogo className="mr-4" />
                        Account Settings
                    </div>
                </>
            ),
        },
        {
            key: '3',
            label: (
                <div
                    onClick={() => {
                        localStorage.removeItem('accessToken');
                        navigate('/login');
                    }}
                    className="flex items-center"
                >
                    <LogoutLogo className="mr-4" />
                    Log Out
                </div>
            ),
        },
    ];

    const nameArray = user?.name?.split(' ');
    const firstName = nameArray?.length > 1 ? nameArray[0] : '-';
    const lasName = nameArray?.length > 1 ? nameArray[1] : '-';

    return (
        <nav className="flex items-center justify-between flex-wrap p-6">
            <div className="flex flex-1" />
            <div className="flex justify-center items-center border-solid border border-greyscale-300 rounded-10 px-5 py-4 bg-white">
                <Dropdown
                    menu={{ items }}
                    className="cursor-pointer"
                    placement="bottom"
                    arrow={{ pointAtCenter: true }}
                    trigger={['click']}
                >
                    <div className="flex">
                        <div className="flex justify-center items-center mr-4">
                            {user?.photo_url ? (
                                <img src={user.photo_url} alt="avatar" className="h-10 w-10 mr-3 rounded-full" />
                            ) : (
                                <div className="flex justify-center items-center text-white font-bold bg-primary h-10 w-10 mr-3 rounded-full">
                                    {firstName[0]}
                                    {lasName[0]}
                                </div>
                            )}

                            <div className="flex flex-col">
                                <Text className="text-base font-bold text-greyscale-900">{user?.name}</Text>
                                <Text className="text-xs font-medium text-greyscale-600">
                                    {active_membership?.account?.name} - {role}
                                </Text>
                            </div>
                        </div>
                        <a href="#" className="flex justify-center items-center h-10 w-10">
                            <DownArrowLogo />
                        </a>
                    </div>
                </Dropdown>
            </div>
        </nav>
    );
};

export default Navbar;
