import React from 'react';

//components
import { Typography } from 'antd';

const { Title } = Typography;

interface ItemProps {
    data: {
        title: string;
        description: string;
        author: string;
        role: string;
    };
}

export default function Item({ data }: ItemProps) {
    const { title, description, author, role } = data;
    return (
        <section key="1">
            <Title level={1} className="mb-4 text-white text-center">
                {title}
            </Title>
            <p className="italic text-lg text-white">{description}</p>
            <section className="flex gap-4 mt-4">
                <p className="flex flex-col">
                    <span className="font-semibold text-white">{author}</span>
                    <span className="text-greyscale-400">{role}</span>
                </p>
            </section>
        </section>
    );
}
