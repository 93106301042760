export enum Colors {
    Transparent  = "transparent",
    Current      = "current",
    Primary      = "primary",
    Secondary    = "secondary",
    White        = "white",
    Orange       = "orange",
    Amber        = "amber",
    Purple       = "purple",
    Pink         = "pink",
    Sky          = "sky", 
    Info         = "info",
    SiteBg       = "site_bg",
    Success      = "success",
    SuccessLight = "success-light",
    SuccessDark  = "success-dark",
    Error        = "error",
    ErrorLight   = "error-light",
    ErrorDark    = "error-dark",
    Greyscale50  = "greyscale-50",
    Greyscale100 = "greyscale-100",
    Greyscale200 = "greyscale-200",
    Greyscale250 = "greyscale-250",
    Greyscale300 = "greyscale-300",
    Greyscale400 = "greyscale-400",
    Greyscale500 = "greyscale-500",
    Greyscale600 = "greyscale-600",
    Greyscale700 = "greyscale-700",
    Greyscale800 = "greyscale-800", 
    Greyscale900 = "greyscale-900"
}

export enum FontWeights {
    Extrabold = "extrabold", 
    Semibold  = "semibold",
    Medium    = "medium",
    Regular   = "regular"
}

export enum FontColors {
    TextPrimary = "text-primary", 
    TextSecondary  = "text-secondary",
    TextDanger    = "text-danger",
    TextGreyScale900   = "text-greyscale-900"
}

export enum FontStyles {
    Italic    = "italic",
    Normal = "normal"
}

export enum FontSizes {
    XLarge = "text-xl",
    Large  = "text-lg",
    Medium = "text-medium",
    Small  = "text-sm",
    XSmall = "text-xs"
}

export enum TextAligns {
    Center  = "text-center",
    Right   = "text-right",
    Left    = "text-left",
    Justify = "text-justify"
}

export enum TextColors {
    Transparent  = "text-transparent",
    Current      = "text-current",
    Primary      = "text-primary",
    Secondary    = "text-secondary",
    White        = "text-white",
    Orange       = "text-orange",
    Amber        = "text-amber",
    Purple       = "text-purple",
    Pink         = "text-pink",
    Sky          = "text-sky", 
    Info         = "text-info",
    SiteBg       = "text-site_bg",
    Success      = "text-success",
    SuccessLight = "text-success-light",
    SuccessDark  = "text-success-dark",
    Error        = "text-error",
    ErrorLight   = "text-error-light",
    ErrorDark    = "text-error-dark",
    Greyscale50  = "text-greyscale-50",
    Greyscale100 = "text-greyscale-100",
    Greyscale200 = "text-greyscale-200",
    Greyscale250 = "text-greyscale-250",
    Greyscale300 = "text-greyscale-300",
    Greyscale400 = "text-greyscale-400",
    Greyscale500 = "text-greyscale-500",
    Greyscale600 = "text-greyscale-600",
    Greyscale700 = "text-greyscale-700",
    Greyscale800 = "text-greyscale-800", 
    Greyscale900 = "text-greyscale-900"
}

export enum BackgroundColors {
    Transparent  = "bg-transparent",
    Current      = "bg-current",
    Primary      = "bg-primary",
    Secondary    = "bg-secondary",
    White        = "bg-white",
    Orange       = "bg-orange",
    Amber        = "bg-amber",
    Purple       = "bg-purple",
    Pink         = "bg-pink",
    Sky          = "bg-sky", 
    Info         = "bg-info",
    SiteBg       = "bg-site_bg",
    Success      = "bg-success",
    SuccessLight = "bg-success-light",
    SuccessDark  = "bg-success-dark",
    Error        = "bg-error",
    ErrorLight   = "bg-error-light",
    ErrorDark    = "bg-error-dark",
    Greyscale50  = "bg-greyscale-50",
    Greyscale100 = "bg-greyscale-100",
    Greyscale200 = "bg-greyscale-200",
    Greyscale250 = "bg-greyscale-250",
    Greyscale300 = "bg-greyscale-300",
    Greyscale400 = "bg-greyscale-400",
    Greyscale500 = "bg-greyscale-500",
    Greyscale600 = "bg-greyscale-600",
    Greyscale700 = "bg-greyscale-700",
    Greyscale800 = "bg-greyscale-800", 
    Greyscale900 = "bg-greyscale-900"
}

export enum BorderColors {
    Transparent  = "border-transparent",
    Current      = "border-current",
    Primary      = "border-primary",
    Secondary    = "border-secondary",
    White        = "border-white",
    Orange       = "border-orange",
    Amber        = "border-amber",
    Purple       = "border-purple",
    Pink         = "border-pink",
    Sky          = "border-sky", 
    Info         = "border-info",
    SiteBg       = "border-site_bg",
    Success      = "border-success",
    SuccessLight = "border-success-light",
    SuccessDark  = "border-success-dark",
    Error        = "border-error",
    ErrorLight   = "border-error-light",
    ErrorDark    = "border-error-dark",
    Greyscale50  = "border-greyscale-50",
    Greyscale100 = "border-greyscale-100",
    Greyscale200 = "border-greyscale-200",
    Greyscale250 = "border-greyscale-250",
    Greyscale300 = "border-greyscale-300",
    Greyscale400 = "border-greyscale-400",
    Greyscale500 = "border-greyscale-500",
    Greyscale600 = "border-greyscale-600",
    Greyscale700 = "border-greyscale-700",
    Greyscale800 = "border-greyscale-800", 
    Greyscale900 = "border-greyscale-900"
}