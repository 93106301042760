import React, { useContext } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// auth routes
import Login from 'pages/login';
import SignUp from 'pages/signup';
import ResetPassword from 'pages/reset-password';
import ForgotPassword from 'pages/forgot-password';

// protected routes
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import ProtectedRoute from 'utils/protected-route';
import Conversations from 'pages/conversations';
import Accounts from 'pages/accounts';
import Appointments from 'pages/appointments';
import Complaints from 'pages/complaints';
import Users from 'pages/users';
import AcceptInvitation from 'pages/accept-invitation';
import Customers from 'pages/customers';
import Integrations from 'pages/integrations';
import Home from 'pages/home';
import CustomerDetail from 'pages/customer-detail';
import ArrivalWindow from 'pages/arrival-window';
import ServiceArea from 'pages/service-area';
import Capacity from 'pages/capacity';
import Services from 'pages/services';
import MatchWithFsm from 'pages/match-with-fsm';
import Preferences from 'pages/preferences';
import Playbook from 'pages/playbook';

// context
import { AuthContext } from 'context';

// helpers
import { Role } from 'utils/constants';

function App() {
    const {
        state: { user },
    } = useContext(AuthContext);

    const isAdmin = user?.role === Role.ADMIN;

    const active_membership_role = user?.memberships?.find(
        (item: any) => item.account.id === user.active_account_id,
    )?.role;

    const routes = [
        {
            path: '/',
            component: <Home />,
        },
        {
            path: '/conversations',
            component: <Conversations />,
        },
        {
            path: '/accounts',
            component: isAdmin ? <Accounts /> : null,
        },
        {
            path: '/users',
            component: <Users />,
        },
        {
            path: '/customers',
            component: <Customers />,
        },
        {
            path: '/integrations',
            component: <Integrations />,
        },
        {
            path: '/customer/:id',
            component: <CustomerDetail />,
        },
        {
            path: '/bookings',
            component: <Appointments />,
        },
        {
            path: '/complaints',
            component: <Complaints />,
        },
        {
            path: '/reset-password',
            component: <ResetPassword />,
        },
        {
            path: '/forgot-password',
            component: <ForgotPassword />,
        },
        {
            path: '/settings/arrival-window',
            component: <ArrivalWindow />,
        },
        {
            path: '/settings/service-area',
            component: <ServiceArea />,
        },
        {
            path: '/settings/capacity',
            component: <Capacity />,
        },
        {
            path: '/settings/services',
            component: <Services />,
        },
        {
            path: '/settings/match-with-fsm',
            component: <MatchWithFsm />,
        },
        {
            path: '/settings/preferences',
            component: <Preferences />,
        },
        {
            path: '/settings/playbook',
            component: <Playbook />,
        },
    ];

    const sidebarItems: any = [
        {
            title: 'Conversations',
            icon: 'MessageMultiple',
            href: '/conversations',
        },
        {
            title: 'Bookings',
            icon: 'Calendar',
            href: '/bookings',
        },
        {
            title: 'Complaints',
            icon: 'Validation',
            href: '/complaints',
            active: false,
        },
        {
            title: 'Customers',
            icon: 'StarFace',
            href: '/customers',
        },
        {
            title: 'Integrations',
            icon: 'Puzzle',
            href: '/integrations',
            showIf: (activeRole: string, user: any) => activeRole === Role.OWNER || user.role === Role.ADMIN,
        },
        {
            title: 'Users',
            icon: 'UserGroup',
            href: '/users',
            showIf: (activeRole: string, user: any) => activeRole === Role.OWNER || user.role === Role.ADMIN,
        },
        {
            title: 'Account',
            icon: 'AccountSetting',
            href: '/accounts',
            showIf: (_: string, user: any) => user.role === Role.ADMIN,
        },
        {
            title: 'General Settings',
            icon: 'Settings',
            href: '#',
            active: false,
            showIf: (activeRole: string, user: any) => activeRole === Role.OWNER || user.role === Role.ADMIN,
            subitems: [
                {
                    title: 'Preferences',
                    href: '/settings/preferences',
                    active: false,
                },
                {
                    title: 'Arrival Window',
                    href: '/settings/arrival-window',
                },
                {
                    title: 'Service Area',
                    href: '/settings/service-area',
                },
                {
                    title: 'Capacity',
                    href: '/settings/capacity',
                },
                {
                    title: 'Services',
                    href: '/settings/services',
                },
                {
                    title: 'Playbook',
                    href: '/settings/playbook',
                },
            ],
        },
    ];

    const filterSidebarItemsByRole = (items: any[], activeRole: string, user: any) => {
        return items.filter((item) => {
            // Check if the item has a 'showIf' function and evaluate it
            if (item.showIf && !item.showIf(activeRole, user)) {
                return false;
            }

            // Recursively filter subitems if they exist
            if (item.subitems) {
                item.subitems = filterSidebarItemsByRole(item.subitems, activeRole, user);
            }

            return true;
        });
    };

    // Get filtered sidebar items based on the active role and user
    const sidebarItemsForRole = filterSidebarItemsByRole(sidebarItems, active_membership_role, user);

    return (
        <BrowserRouter basename={'/'}>
            <Routes>
                <Route path="login" element={<Login />} />
                <Route path="signup" element={<SignUp />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="accept-invitation" element={<AcceptInvitation />} />
                <Route
                    path="*"
                    element={
                        <ProtectedRoute>
                            <div className="flex min-h-screen bg-greyscale-150">
                                <Sidebar logo="Buck" items={sidebarItemsForRole} />
                                <div className="flex flex-1 flex-col overflow-hidden">
                                    <Navbar />
                                    <div className="flex flex-1 overflow-x-hidden overflow-y-auto p-6">
                                        <Routes>
                                            {routes.map(({ path, component }) => (
                                                <Route key={path} path={path} element={component} />
                                            ))}
                                        </Routes>
                                    </div>
                                </div>
                            </div>
                        </ProtectedRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
