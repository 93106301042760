import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Skeleton, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';

// helpers
import { Source } from 'utils/constants';
import { convertTimeToAccountTimezone } from 'utils/formatter';

interface IMessages {
    id: string;
    source: string;
    content: string;
    status: string;
    created_at: string;
    updated_at: string;
}

interface ICustomer {
    id: string;
    name: string;
}

interface IProps {
    messages: IMessages[];
    customer: ICustomer;
    totalMessageCount: number;
    getMessages: () => void;
}

const Thread: React.FC<IProps> = ({ customer, messages, totalMessageCount, getMessages }) => {
    const navigate = useNavigate();

    return (
        <div id="scrollableDivThread" className="h-full relative overflow-scroll">
            <div className="absolute flex flex-col w-full">
                <InfiniteScroll
                    dataLength={messages.length}
                    next={getMessages}
                    hasMore={messages.length < totalMessageCount}
                    loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                    endMessage={<Divider plain>No more data to load</Divider>}
                    scrollableTarget="scrollableDivThread"
                    className="absolute flex flex-col w-full"
                >
                    {messages.map((message, index) => (
                        <div
                            className={`flex flex-col max-w-lg mb-4 ${
                                message?.source === Source.CUSTOMER ? 'self-start' : 'self-end'
                            }`}
                            key={index}
                        >
                            <div
                                className={`py-3 px-5 mb-1 flex flex-col
                                        ${
                                            message?.source === Source.CUSTOMER
                                                ? ' text-gray-800'
                                                : 'bg-sky-100 rounded'
                                        }`}
                            >
                                <div className="flex items-center text-sm font-medium mb-1">
                                    {message?.source === Source.CUSTOMER ? (
                                        <div
                                            className="text-info cursor-pointer"
                                            onClick={() => navigate(`/customer/${customer?.id}`)}
                                        >
                                            {customer?.name}
                                        </div>
                                    ) : (
                                        <div className="text-greyscale-500">Buck.AI</div>
                                    )}
                                    <div className="text-xs text-greyscale-500 ml-2">
                                        {message?.created_at ? convertTimeToAccountTimezone(message.created_at) : '-'}
                                    </div>
                                </div>
                                <div className="flex flex-wrap text-base font-normal whitespace-pre-wrap">
                                    {message?.content}
                                </div>
                            </div>
                        </div>
                    ))}
                </InfiniteScroll>
            </div>
        </div>
    );
};

export default Thread;
