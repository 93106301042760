import { useLocation } from 'react-router-dom';

function useQuery(queryName: string) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryValue = queryParams.get(queryName);

    // return url query Value
    return queryValue;
}

export default useQuery;
