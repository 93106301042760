import React, { useState, useEffect } from 'react';

// components
import { Input, Table, Select, Button } from 'antd';
import type { TableColumnsType } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import notify from 'utils/notification';
import AddTag from 'dialogs/add-tag';

// assets
import { AddLogo, SearchLogo, SortByDownSvg, SortByUpSvg, LeftArrowLogo, RightArrowLogo, SaveLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';

type SortOrder = 'ascend' | 'descend' | null;
type OptionProps = {
    id: string;
    name: string;
};

type IFsm = {
    id: string;
    service: string;
    fsm_business_unit: string;
    fsm_job_type: string;
    fsm_tags: string;
    key: string;
};

type ITag = {
    id: string;
    name: string;
};

type IActiveTag = {
    serviceId: string;
    active: boolean;
    tags: ITag[];
};

interface IServices {
    [key: string]: {
        active: boolean;
        fsm_options: {
            business_unit: {
                name: string;
                id: string;
            };
            job_type: {
                name: string;
                id: string;
            };
            tags: ITag[];
        };
    };
}

const ArrivalWindow = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [services, setServices] = useState<any[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<any>(new Map());
    const [options, setOptions] = useState<any>({});
    const [activeTag, setActiveTag] = useState<IActiveTag>({ serviceId: '', active: false, tags: [] });
    const [addTagVisible, setAddTagVisible] = useState<boolean>(false);

    const handleAddTagClicked = ({ serviceId, active, tags }: { serviceId: string; active: boolean; tags: ITag[] }) => {
        setActiveTag({
            serviceId,
            active,
            tags,
        });
        setAddTagVisible(true);
    };

    const getServices = async () => {
        try {
            setIsLoading(true);
            const response = await axiosClient.get(apiURL.services, { params: { 'filter[active]': true } });
            const data = response?.data;
            setServices(data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const getOptions = async () => {
        try {
            const response = await axiosClient.get(apiURL.fsmOptions);
            const fsm = response?.data;
            setOptions({
                fsm_job_types: fsm?.data?.job_types || [],
                fsm_business_units: fsm?.data?.business_units || [],
                fsm_tags: fsm?.data?.tags || [],
            });
        } catch (error) {
            notify({ type: 'error', message: 'Failed to fetch options' });
            console.error(error);
        }
    };

    const handleBusinessUnitChange = async (recordId: string, active: boolean, option: any) => {
        if (!option || !recordId) {
            return;
        }

        const business_unit = {
            id: option.value,
            name: option.label,
        };

        setServices((prev: any) => {
            return prev.map((service: any) => {
                if (service.id === recordId) {
                    return {
                        ...service,
                        fsm_options: {
                            ...service?.fsm_options,
                            business_unit,
                        },
                    };
                }
                return service;
            });
        });

        setSelectedOptions((prev: any) => {
            const data = prev.get(recordId);
            return new Map([
                ...prev,
                [recordId, { ...data, active, fsm_options: { ...data?.fsm_options, business_unit } }],
            ]);
        });
    };

    const handleJobTypeChange = async (recordId: string, active: boolean, option: any) => {
        if (!option || !recordId) {
            return;
        }

        const job_type = {
            id: option.value,
            name: option.label,
        };

        setServices((prev: any) => {
            return prev.map((service: any) => {
                if (service.id === recordId) {
                    return {
                        ...service,
                        fsm_options: {
                            ...service?.fsm_options,
                            job_type,
                        },
                    };
                }
                return service;
            });
        });
        setSelectedOptions((prev: any) => {
            const data = prev.get(recordId);
            return new Map([...prev, [recordId, { ...data, active, fsm_options: { ...data?.fsm_options, job_type } }]]);
        });
    };

    const handleAddTagSave = async (tags: any) => {
        if (!activeTag?.serviceId) {
            notify({ type: 'error', message: 'Please select a service' });
            return;
        }

        setServices((prev: any) => {
            return prev.map((service: any) => {
                if (service.id === activeTag?.serviceId) {
                    return {
                        ...service,
                        fsm_options: {
                            ...service?.fsm_options,
                            tags: tags.map((tag: any) => ({ id: tag?.value, name: tag?.label })),
                        },
                    };
                }
                return service;
            });
        });

        setSelectedOptions((prev: any) => {
            const data = prev.get(activeTag?.serviceId);
            return new Map([
                ...prev,
                [
                    activeTag?.serviceId,
                    {
                        ...data,
                        active: activeTag?.active,
                        fsm_options: {
                            ...data?.fsm_options,
                            tags: tags.map((tag: any) => ({ id: tag?.value, name: tag?.label })),
                        },
                    },
                ],
            ]);
        });
        setActiveTag({ serviceId: '', active: false, tags: [] });
        setAddTagVisible(false);
    };

    const handleSave = async () => {
        if (!selectedOptions.size) {
            notify({ type: 'error', message: 'Please select all required fields.' });
            return;
        }

        const services: IServices = {};
        let allServicesValid = true;

        selectedOptions.forEach((value: any, key: string) => {
            const fsmOptions = value.fsm_options;

            if (!fsmOptions.business_unit || !fsmOptions.job_type || !fsmOptions.tags) {
                allServicesValid = false;
                console.warn(`Service ID ${key} is missing required fields.`);
            } else {
                services[key] = {
                    active: value.active,
                    fsm_options: {
                        business_unit: {
                            name: fsmOptions.business_unit.name,
                            id: fsmOptions.business_unit.id,
                        },
                        job_type: {
                            name: fsmOptions.job_type.name,
                            id: fsmOptions.job_type.id,
                        },
                        tags: fsmOptions.tags.map((tag: OptionProps) => ({
                            name: tag.name,
                            id: tag.id,
                        })),
                    },
                };
            }
        });

        if (!allServicesValid) {
            notify({
                type: 'error',
                message: `Some services are missing required fields. Please check the logs for details.`,
            });
        } else {
            const payload = {
                services: Object.keys(services).map((key) => {
                    return {
                        id: key,
                        attributes: {
                            active: services[key].active,
                            fsm_options: services[key].fsm_options,
                        },
                    };
                }),
            };
            try {
                const response = await axiosClient.put(`${apiURL.services}/batch`, payload);
                if (response?.status === 200) {
                    notify({ type: 'success', message: 'Successfully saved' });
                }
            } catch (error) {
                console.error(error);
                notify({ type: 'error', message: 'Failed to save' });
            }
        }
    };

    const handleAddTagClose = () => {
        setActiveTag({ serviceId: '', active: false, tags: [] });
        setAddTagVisible(false);
    };

    const columns: TableColumnsType<IFsm> = [
        {
            title: 'SERVICE',
            dataIndex: 'service',
            key: 'service',
            ...sortColums,
            width: 90,
            fixed: 'left',
            render: (_: any, record: any) => (
                <div>
                    {record.trade.name}/{record.equipment_type?.name}/{record?.service_type?.name}
                </div>
            ),
        },
        {
            title: 'FSM BUSINESS UNIT',
            dataIndex: 'fsm_business_unit',
            key: 'fsm_business_unit',
            ...sortColums,
            width: 120,
            render: (_: any, record: any) => {
                return (
                    <Select
                        onChange={(_, option: any) => handleBusinessUnitChange(record?.id, record?.active, option)}
                        className="w-80"
                        value={record?.fsm_options?.business_unit?.id}
                        options={options?.fsm_business_units?.map((el: OptionProps) => {
                            return { value: el.id, label: el.name };
                        })}
                    />
                );
            },
        },
        {
            title: 'FSM JOB TYPE',
            dataIndex: 'fsm_job_type',
            key: 'fsm_job_type',
            width: 120,
            render: (_: any, record: any) => {
                return (
                    <Select
                        onChange={(_, option: any) => handleJobTypeChange(record.id, record?.active, option)}
                        value={record?.fsm_options?.job_type?.id}
                        className="w-80"
                        options={options?.fsm_job_types?.map((el: OptionProps) => {
                            return { value: el.id, label: el.name };
                        })}
                    />
                );
            },
        },
        {
            title: 'FSM TAGS',
            dataIndex: 'fsm_tags',
            key: 'fsm_tags',
            width: 120,
            render: (_: any, record: any) => {
                if (record?.fsm_options?.tags?.length) {
                    return (
                        <div
                            className="flex cursor-pointer"
                            onClick={() =>
                                handleAddTagClicked({
                                    serviceId: record.id,
                                    active: record.active,
                                    tags: record?.fsm_options?.tags,
                                })
                            }
                        >
                            <div className="border border-solid border-greyscale-250 rounded-20 px-3 py-1 max-w-24 truncate">
                                {record?.fsm_options?.tags[0].name}
                            </div>
                            {record?.fsm_options?.tags.length > 1 && (
                                <div className="border border-solid border-greyscale-250 rounded-20 px-3 py-1 ml-3 max-w-10">
                                    +{record?.fsm_options?.tags.length - 1}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    return (
                        <Button
                            onClick={() =>
                                handleAddTagClicked({
                                    serviceId: record.id,
                                    active: record.active,
                                    tags: record?.fsm_options?.tags,
                                })
                            }
                            className="flex w-32 items-center justify-between"
                        >
                            <span>Add Tag</span>
                            <AddLogo className="ml-5" />
                        </Button>
                    );
                }
            },
        },
    ];

    useEffect(() => {
        getOptions();
        getServices();
    }, []);

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="mt-12">
                <div className="flex justify-between mb-6">
                    <Input prefix={<SearchLogo />} className="max-w-sm" placeholder="Search on table" />
                    <Button onClick={handleSave} type="primary" icon={<SaveLogo />}>
                        Save
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    pagination={{ itemRender }}
                    loading={isLoading}
                    dataSource={services.map((service) => ({ ...service, key: service.id }))}
                    columns={columns}
                />
            </div>
            <AddTag
                options={options?.fsm_tags || []}
                activeTag={activeTag}
                visible={addTagVisible}
                onClose={handleAddTagClose}
                onSave={handleAddTagSave}
            />
        </div>
    );
};

const sortColums = {
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
    sortIcon: ({ sortOrder }: any) => {
        if (!sortOrder || sortOrder === 'ascend') {
            return <SortByDownSvg />;
        }
        return <SortByUpSvg className="text-primary" />;
    },
};

const itemRender = (current: number, type: string, originalElement: any) => {
    if (type === 'prev') {
        return <LeftArrowLogo />;
    }
    if (type === 'next') {
        return <RightArrowLogo />;
    }
    return originalElement;
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Services',
        href: '/settings/services',
        disabled: true,
    },
    {
        label: 'Match with FSM',
        href: '/settings/services',
    },
];

export default ArrivalWindow;
