import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Card, Table, Input } from 'antd';
import type { TableColumnsType } from 'antd';
import { SearchLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

interface ICustomer {
    id?: string;
    name: string;
    conversations_count: number;
    bookings_count: number;
}

export default function Customers() {
    const navigate = useNavigate();

    const [customers, setCustomers] = useState<ICustomer[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getCustomers = async () => {
        setIsLoading(true);
        try {
            const res = await axiosClient.get(`${apiURL.customers}?page=${currentPage}`);
            setCustomers(res?.data?.data?.map((account: ICustomer) => ({ ...account, key: account.id })) || []);

            setTotalCount(res?.data?.meta.total);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customers' });
            console.error('error fetching customers', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCustomers();
    }, [currentPage]);

    const columns: TableColumnsType<ICustomer> = [
        {
            title: 'CUSTOMER NAME',
            dataIndex: 'name',
            key: 'name',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'ACTIVE CONVERSATIONS',
            dataIndex: 'conversations_count',
            key: 'conversations_count',
            sorter: (a: ICustomer, b: ICustomer) => a.conversations_count - b.conversations_count,
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'BOOKINGS',
            dataIndex: 'bookings_count',
            key: 'bookings_count',
            sorter: (a: ICustomer, b: ICustomer) => a.bookings_count - b.bookings_count,
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
    ];

    return (
        <Card className="flex-1 h-min" bordered={false}>
            <div>
                <div className="flex justify-between mb-5">
                    <Input
                        className="max-w-lg"
                        placeholder="Search Customers"
                        prefix={<SearchLogo className="w-4" />}
                    />
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    rowClassName="cursor-pointer"
                    loading={isLoading}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                navigate(`/customer/${record?.id}`);
                            },
                        };
                    }}
                    dataSource={customers}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
        </Card>
    );
}
