import React, { useEffect } from 'react';

// components
import { Button, Col, Row, Input, Form } from 'antd';

// assets
import { MinusLogo, PlusLogo } from 'assets/svg';

interface CompanyInformationProps {
    visibleMenus: string[];
    companyInformation: any;
    setPlaybook: (prevState: any) => void;
    setVisibleMenus: (prevState: any) => void;
}

const pageKey = 'company-information';
const CompanyInformation: React.FC<CompanyInformationProps> = ({
    companyInformation = {},
    visibleMenus,
    setPlaybook,
    setVisibleMenus,
}) => {
    const [form] = Form.useForm();

    const handleChange = (e: any) => {
        const { id, value } = e.target;
        setPlaybook((prevState: any) => {
            return {
                ...prevState,
                company_information: {
                    ...prevState.company_information,
                    [id]: value,
                },
            };
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            phone_number: companyInformation?.phone_number || '',
            full_address: companyInformation?.full_address || '',
        });
    }, [form, companyInformation]);

    return (
        <div>
            <div className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}>
                <div className="flex justify-between items-center mb-6">
                    <div className="font-bold text-lg text-greyscale-900">Company Information</div>

                    <Button
                        onClick={() =>
                            setVisibleMenus((prevState: any) =>
                                prevState.includes(pageKey)
                                    ? prevState.filter((item: string) => item !== pageKey)
                                    : [...prevState, pageKey],
                            )
                        }
                        className={`w-6 h-6 p-0 ${visibleMenus.includes(pageKey) && 'bg-greyscale-100'}`}
                    >
                        {!visibleMenus.includes(pageKey) ? <MinusLogo /> : <PlusLogo />}
                    </Button>
                </div>
                {!visibleMenus.includes(pageKey) && (
                    <div>
                        <Form layout="vertical" form={form} onChange={handleChange}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item label="Phone Number" name="phone_number">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Address" name="full_address">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CompanyInformation;
