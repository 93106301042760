import React from 'react';
import { Link } from 'react-router-dom';

// assets
import { RightArrowLogo } from 'assets/svg';

interface IBreadCrumbItem {
    label: string;
    href: string;
    disabled?: boolean;
}

interface IBreadCrumbProps {
    items: { label: string; href: string; disabled?: boolean }[];
}

const Breadcrump: React.FC<IBreadCrumbProps> = ({ items = [] }) => {
    return (
        <nav className="flex">
            {items?.map((item: IBreadCrumbItem, index: number) => (
                <div className="flex items-center" key={index}>
                    {item.disabled ? (
                        <span className="text-greyscale-400 text-lg font-bold">{item.label}</span>
                    ) : (
                        <Link className="text-greyscale-900 text-lg font-bold no-underline" to={item.href}>
                            {item.label}
                        </Link>
                    )}
                    {index < items?.length - 1 && <RightArrowLogo className="mx-1.5" />}
                </div>
            ))}
        </nav>
    );
};

export default Breadcrump;
