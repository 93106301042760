import React, { createContext, useReducer, ReactNode } from 'react';

interface Actions {
    [key: string]: (dispatch: React.Dispatch<any>) => any;
}

interface CreateDataContextProps {
    reducer: (state: any, action: any) => any;
    actions: Actions;
    initialState: any;
}

const createDataContext = ({ reducer, actions, initialState }: CreateDataContextProps) => {
    const Context = createContext<any>(undefined);

    const Provider: React.FC<{ children: ReactNode }> = ({ children }) => {
        const [state, dispatch] = useReducer(reducer, initialState);

        const boundedActions: Actions = {};
        for (const key in actions) {
            boundedActions[key] = actions[key](dispatch);
        }

        return <Context.Provider value={{ state, ...boundedActions }}>{children}</Context.Provider>;
    };

    return { Context, Provider };
};

export default createDataContext;
