import React, { useState, useEffect } from 'react';

// components
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { RightArrowLogo } from 'assets/svg';

// styles
import { ILocationItem } from 'pages/customer-detail/customer-detail';

interface IGroupedLocationItem {
    country: string;
    locations: ILocationItem[];
}

interface IProps {
    locations?: ILocationItem[];
    visible: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
}

const CustomerLocationModal: React.FC<IProps> = ({ locations, visible, onClose }) => {
    const [selectedLocation, setSelectedLocation] = useState<ILocationItem | undefined>(undefined);
    const [map, setMap] = useState<any>(null);
    const [groupedLocations, setGroupedLocations] = useState<IGroupedLocationItem[]>([]);

    const handleLocationClick = (location: ILocationItem) => {
        setSelectedLocation(location);
        map?.setView([location.latitude, location.longitude]);
    };

    useEffect(() => {
        if (!locations?.length) return;
        const groupedLocationsForCountries = locations?.reduce(
            (acc: IGroupedLocationItem[], location: ILocationItem) => {
                const existingCountry = acc.find((item) => item.country === location.country);

                if (!existingCountry) {
                    acc.push({ country: location.country, locations: [location] });
                } else {
                    existingCountry.locations.push(location);
                }

                return acc;
            },
            [],
        );

        setGroupedLocations(groupedLocationsForCountries);
        setSelectedLocation(groupedLocationsForCountries[0].locations[0]);
    }, [locations, visible]);

    return (
        <Modal
            centered
            className="w-[818px] "
            classNames={{ body: 'h-[587px] flex flex-1 flex-col' }}
            closeIcon={<CloseCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="text-xl font-semibold text-grey-scale-900 mb-6">Locations</div>
            <div className="flex flex-1">
                <div className="w-60 max-h-[535px] overflow-y-scroll">
                    {groupedLocations?.map((el, index) => {
                        return (
                            <div key={el.country}>
                                <div className={`${index !== 0 && 'mt-6'} flex mb-3 text-sm font-semibold`}>
                                    <div className="text-primary-900 mr-3">{el.country}</div>
                                    <div className="text-info">{el.locations.length}</div>
                                </div>
                                <div className="rounded-10 border-solid border-greyscale-100">
                                    {el.locations.map((location, index) => (
                                        <div
                                            key={location.id}
                                            onClick={() => handleLocationClick(location)}
                                            className={`flex items-center justify-between p-6 hover:bg-amber-100 cursor-pointer ${
                                                selectedLocation?.id === location?.id
                                                    ? 'bg-amber-100 border-solid border-y-0 border-r-0 border-l-4 border-l-primary'
                                                    : ''
                                            } ${
                                                index !== el.locations.length - 1
                                                    ? 'border-solid border-b border-x-0 border-y-0 border- border-greyscale-100'
                                                    : ''
                                            }`}
                                        >
                                            <div className="flex flex-col">
                                                <div className="text-greyscale-500 text-xs font-medium">
                                                    {location?.city}
                                                </div>
                                                <div className="font-normal text-sm max-w-48 text-ellipsis overflow-hidden truncate relative">
                                                    {location?.full_address}
                                                </div>
                                            </div>
                                            <div>{selectedLocation?.id === location.id && <RightArrowLogo />}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="flex flex-1 ml-6">
                    {selectedLocation?.latitude && (
                        <MapContainer
                            style={{ flex: 1 }}
                            center={[selectedLocation.latitude, selectedLocation.longitude]}
                            zoom={10}
                            scrollWheelZoom={false}
                            ref={setMap}
                        >
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[selectedLocation.latitude, selectedLocation.longitude]}>
                                <Popup>{selectedLocation?.full_address}</Popup>
                            </Marker>
                        </MapContainer>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default CustomerLocationModal;
