import React, { useState, useEffect } from 'react';

// components
import { Button, Input, Table, Dropdown, Popconfirm } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import AddEditServiceArea from 'dialogs/add-edit-service-area';
import notify from 'utils/notification';
import type { MenuProps } from 'antd';

// assets
import { AddCircleLogo, DotVertical, EditIcon, SearchLogo, SortByDownSvg, SortByUpSvg } from 'assets/svg';

// helpers
import { debounce } from 'utils/debounce';
import { axiosClient, apiURL } from 'service';
import { convertTimeToAccountTimezone } from 'utils/formatter';

interface DataType {
    key: string;
    id: string;
    name: string;
    zip_codes: string[];
    created_at: string;
    updated_at: string;
}

type SortOrder = 'ascend' | 'descend' | null;

const ServiceArea = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [serviceAreaList, setServiceAreaList] = useState<any[]>([]);
    const [selectedServiceArea, setSelectedServiceArea] = useState<any | null>(null);
    const [addEditServiceAreaVisible, setAddEditServiceAreaVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const getServiceAreaList = async () => {
        setIsLoading(true);
        axiosClient
            .get(`${apiURL.zones}?page=${currentPage}`)
            .then((response) => {
                const data = response?.data?.data?.map((el: any, i: any) => ({ key: i, ...el })) || [];
                setServiceAreaList(data);

                setTotalCount(response?.data?.meta.total);
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.message || 'Error fetching service area.' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSave = () => {
        getServiceAreaList();
        setSelectedServiceArea(null);
        setAddEditServiceAreaVisible(false);
    };

    const handleDelete = (id: string) => {
        setIsLoading(true);
        axiosClient
            .delete(`${apiURL.zones}/${id}`)
            .then(() => {
                notify({ type: 'success', message: 'Service Area deleted successfully' });
                getServiceAreaList();
            })
            .catch((error) => {
                notify({ type: 'error', message: `Error deleting service area: ${error?.toString()}` });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const debouncedHandleSearch = debounce((value: string) => {
        axiosClient
            .get(`${apiURL.zones}?q=${value}`)
            .then((response) => {
                const data = response?.data?.data?.map((el: any, i: any) => ({ key: i, ...el })) || [];
                setServiceAreaList(data);
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.message || 'Error fetching zip codes.' });
            });
    }, 300);

    const handleSearchServiceArea = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        debouncedHandleSearch(value);
    };

    const sortColums = {
        sorter: true,
        sortDirections: ['ascend', 'descend', 'ascend'] as SortOrder[],
        sortIcon: ({ sortOrder }: any) => {
            if (!sortOrder || sortOrder === 'ascend') {
                return <SortByDownSvg />;
            }
            return <SortByUpSvg className="text-primary" />;
        },
    };

    const columns = [
        {
            title: 'TITLE',
            dataIndex: 'name',
            key: 'name',
            ...sortColums,
        },
        {
            title: 'ZIP CODE',
            dataIndex: 'zip_codes',
            key: 'zip_codes',
            render: (zipCodes: string[]) => (
                <div className="flex items-center">
                    <div className="mr-3">{zipCodes?.[0]}</div>
                    {zipCodes.length - 1 > 0 && (
                        <Dropdown
                            placement="bottom"
                            dropdownRender={() => (
                                <div className="rounded-10 bg-white shadow-dropdown-menu w-52">
                                    <div className="pt-6 px-6 text-sm text-black font-medium">ZIP CODES</div>
                                    {zipCodes.map((item, i) => (
                                        <div
                                            key={item}
                                            className={`${
                                                i !== zipCodes.length - 1 &&
                                                'border-solid border-b border-x-0 border-y-0 border-greyscale-200'
                                            } py-4 px-6 text-sm text-greyscale-900`}
                                        >
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            )}
                        >
                            <div className="cursor-pointer border-greyscale-200 border-solid rounded-10 flex items-center justify-center w-10 h-6 text-greyscale-900 font-xs">
                                +{zipCodes.length - 1}
                            </div>
                        </Dropdown>
                    )}
                </div>
            ),
            ...sortColums,
        },
        {
            title: 'DATA CREATED',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (item: string) => <div>{item ? convertTimeToAccountTimezone(item) : '-'}</div>,
            ...sortColums,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            render: (_: any, record: any) => {
                const items: MenuProps['items'] = [
                    {
                        key: '1',
                        label: (
                            <div className="flex items-center justify-center">
                                <Popconfirm
                                    title="Are you sure you want to delete this record?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={() => {
                                        handleDelete(record.id);
                                    }}
                                >
                                    <div>Delete</div>
                                </Popconfirm>
                            </div>
                        ),
                    },
                ];

                return (
                    <div className="flex items-center">
                        <EditIcon
                            className="mr-2 cursor-pointer"
                            onClick={() => {
                                setSelectedServiceArea(record);
                                setAddEditServiceAreaVisible(true);
                            }}
                        />
                        <Dropdown menu={{ items }} placement="bottom" arrow={{ pointAtCenter: true }}>
                            <DotVertical className="mr-2 cursor-pointer" />
                        </Dropdown>
                    </div>
                );
            },
        },
    ];

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: DataType[]) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: (record: DataType) => ({
            title: record.name,
        }),
    };

    useEffect(() => {
        getServiceAreaList();
    }, [currentPage]);

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="mt-12">
                <div className="flex justify-between mb-6">
                    <Input
                        prefix={<SearchLogo />}
                        onChange={handleSearchServiceArea}
                        className="max-w-sm"
                        placeholder="Search on table"
                    />
                    <Button icon={<AddCircleLogo />} onClick={() => setAddEditServiceAreaVisible(true)} type="primary">
                        Create New Service Area
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    loading={isLoading}
                    dataSource={serviceAreaList}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
            <AddEditServiceArea
                serviceArea={selectedServiceArea}
                visible={addEditServiceAreaVisible}
                onClose={() => {
                    setAddEditServiceAreaVisible(false);
                    setSelectedServiceArea(null);
                }}
                onSave={() => handleSave()}
            />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Service Area',
        href: '/settings/service-area',
    },
];

export default ServiceArea;
