import React, { useEffect, useState } from 'react';

// components
import { Button, Input, Modal, Col, Form } from 'antd';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

// assets
import { SaveLogo } from 'assets/svg';

interface IProps {
    serviceArea?: any;
    visible: boolean;
    onClose: () => void;
    onSave: () => void;
}

const AddEditServiceArea: React.FC<IProps> = ({ serviceArea, visible, onSave, onClose }) => {
    const [form] = Form.useForm();
    const [zipCodes, setZipCodes] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const handleSaveClick = async (values: any) => {
        setLoading(true);
        const payload = {
            name: values.name,
            zip_codes: values.zip_codes,
        };

        try {
            if (serviceArea?.id) {
                await axiosClient.put(`${apiURL.zones}/${serviceArea.id}`, payload);
            } else {
                await axiosClient.post(apiURL.zones, payload);
            }
            notify({ type: 'success', message: 'Service area saved successfully!' });

            if (onSave) {
                onSave();
            }
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Failed to save service area. Please try again.' });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e: any) => {
        const value = e.target.value;
        const zipCodes = value.split(',');
        form.setFieldsValue({
            zip_codes: zipCodes,
        });
    };

    const handleOk = () => {
        form.submit();
    };

    useEffect(() => {
        if (serviceArea?.id) {
            form.setFieldsValue({
                name: serviceArea.name,
                zip_codes: serviceArea.zip_codes,
            });
        } else {
            form.resetFields();
        }
    }, [serviceArea?.id, form]);

    useEffect(() => {
        axiosClient
            .get(`${apiURL.zones}`)
            .then((response) => {
                const zipCodes = response?.data?.data?.map((el: any) => el.zip_codes) || [];
                setZipCodes(zipCodes.flat());
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.message || 'Error fetching zip codes.' });
            });
    }, []);

    return (
        <Modal
            forceRender
            width={450}
            title={serviceArea?.id ? 'Edit Service Area' : 'Add New'}
            open={visible}
            onCancel={onClose}
            okText="Save"
            onOk={handleOk}
            footer={[
                [
                    <div key="footer" className="flex flex-1">
                        <Button
                            className="flex-1"
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleOk}
                            icon={serviceArea?.id ? <SaveLogo /> : null}
                        >
                            {serviceArea?.id ? 'Save' : 'Add Service Area'}
                        </Button>
                    </div>,
                ],
            ]}
        >
            <Form layout="vertical" form={form} onFinish={handleSaveClick}>
                <Col span={24}>
                    <Form.Item label="Title" name="name" rules={[{ required: true, message: 'Please add a title!' }]}>
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Form.Item
                        label="Zip Code"
                        name="zip_codes"
                        rules={[{ required: true, message: 'Please add zip codes!' }]}
                    >
                        <Input value={zipCodes.join(',')} onChange={handleChange} placeholder="Zip Code" />
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    );
};

export default AddEditServiceArea;
