import React, { useState, useEffect } from 'react';

// components
import { Col } from 'antd';
import Items from './item';

// data
import itemsData from './data.json';

const SLIDER_INTERVAL = 3000;

const WelcomeBanner: React.FC = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % 4);
        }, SLIDER_INTERVAL);

        return () => clearInterval(interval);
    }, []);

    return (
        <Col className="bg-primary-900 hidden xl:flex items-center justify-center" xl={12}>
            <section className="max-w-lg overflow-hidden" data-controller="slider">
                <section data-slider-target="items">
                    {[0, 1, 2, 3].map((index) => (
                        <section
                            key={index}
                            className={`animate-fade-up ${index === activeIndex ? 'visible' : 'hidden'}`}
                        >
                            <Items data={itemsData[index]} />
                        </section>
                    ))}
                </section>

                <section data-slider-target="navigations" className="flex gap-2 mt-6 justify-center">
                    {[0, 1, 2, 3].map((index) => (
                        <div
                            key={index}
                            className={`h-1.5 w-1.5 rounded-full cursor-pointer transition-transform ${
                                index === activeIndex ? 'bg-white transform w-6 scale-125' : 'bg-white/50 '
                            }`}
                        ></div>
                    ))}
                </section>
            </section>
        </Col>
    );
};

export default WelcomeBanner;
