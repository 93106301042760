import { notification } from 'antd';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type INotify = {
    type: NotificationType;
    message: any;
    description?: string;
};

const notify = ({ type, message, description }: INotify) => {
    notification[type]({ message, description, duration: 3, placement: 'topRight' });
};

export default notify;
