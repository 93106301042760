import React from 'react';

// components
import { TriangleErrorLogo } from 'assets/svg';

interface IProps {
    title: string;
    description: string;
}

const Empty: React.FC<IProps> = ({ title, description }) => {
    return (
        <div className="flex flex-col items-center justify-center h-full w-full">
            <div className="w-12 h-12 flex items-center justify-center bg-warning-100 rounded-full">
                <TriangleErrorLogo />
            </div>
            <div className="text-center">
                <h1 className="text-lg font-medium text-greyscale-900">{title}</h1>
                <p className="text-sm font-normal text-greyscale-600">{description}</p>
            </div>
        </div>
    );
};

export default Empty;
