import React, { useContext } from 'react';

// components
import { Col, Form, Input, Row, Switch, Typography } from 'antd';
import WelcomeBanner from 'components/welcome-banner';
import Spinner from 'components/spinner';

// styles
import { BuckLogo } from 'assets/svg';

// helpers
import { AuthContext } from 'context';

const { Title, Text } = Typography;

// types
interface LoginFormValues {
    email: string;
    password: string;
    remember: boolean;
}

const Login: React.FC = () => {
    const {
        state: { isLoading },
        signIn,
    } = useContext(AuthContext);
    const [form] = Form.useForm();

    const onSubmit = (values: LoginFormValues) => {
        signIn({
            user: values,
        });
    };

    return (
        <div className="flex h-screen">
            <Row className="flex-1">
                <Col className="flex flex-col flex-1 xl:items-start justify-between p-4 xl:p-12" xl={12}>
                    <BuckLogo className="h-12 w-min" />
                    <section className="flex flex-col mx-auto max-w-lg w-full text-center gap-6">
                        <div className="flex flex-col gap-2.5">
                            <Title level={2}>Sign in</Title>
                            <Text className="text-greyscale-600">Sign in for dashboard and platform access.</Text>
                        </div>
                        <Form form={form} initialValues={{ remember: true }} onFinish={onSubmit}>
                            <Form.Item
                                className="mb-4"
                                name="email"
                                rules={[{ required: true, message: 'Please input your Username!' }]}
                            >
                                <Input prefix={<span className="icon-user"></span>} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                className="mb-2"
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<span className="icon-lock"></span>}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item className="mb-2">
                                <div className="flex flex-1 justify-between">
                                    <div>
                                        <Switch defaultChecked />{' '}
                                        <label className="ml-3 text-grayscale-900 text-sm font-semibold cursor-pointer">
                                            Remember me
                                        </label>
                                    </div>
                                    <a
                                        className="text-primary font-semibold hover:text-primary-500 hover:underline"
                                        href="/forgot-password"
                                    >
                                        Forgot password
                                    </a>
                                </div>
                            </Form.Item>

                            <Form.Item>
                                <Input type="submit" value="Sign In" />
                            </Form.Item>
                        </Form>
                    </section>
                    <footer className="flex justify-between w-full">
                        <a
                            href="https://buck.ai/terms"
                            className="text-primary hover:text-primary-500 hover:underline font-semibold"
                        >
                            Privacy Policy
                        </a>
                        <span className="text-greyscale-600 font-semibold text-sm">Copyright © 2022</span>
                    </footer>
                </Col>
                <WelcomeBanner />
            </Row>
            <Spinner visible={isLoading} />
        </div>
    );
};

export default Login;
