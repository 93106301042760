import { ReactComponent as Delete } from 'assets/icons/delete.svg';
import { ReactComponent as Buck } from 'assets/icons/buck.svg';
import { ReactComponent as AccountSetting } from 'assets/icons/account-setting.svg';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as MessageMultiple } from 'assets/icons/message-multiple.svg';
import { ReactComponent as Puzzle } from 'assets/icons/puzzle.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as StarFace } from 'assets/icons/star-face.svg';
import { ReactComponent as UserGroup } from 'assets/icons/user-group.svg';
import { ReactComponent as Validation } from 'assets/icons/validation.svg';
import { ReactComponent as PlusSign } from 'assets/icons/plus-sign.svg';
import { ReactComponent as CircleWithDot } from 'assets/icons/circle-with-dot.svg';
import { ReactComponent as MinusSign } from 'assets/icons/minus-sign.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';

export const icons = {
    Delete,
    Buck,
    AccountSetting,
    Calendar,
    MessageMultiple,
    Puzzle,
    Settings,
    StarFace,
    UserGroup,
    Validation,
    PlusSign,
    CircleWithDot,
    MinusSign,
    ArrowRight,
};
