import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// components
import { Card, Breadcrumb, Row, Col, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import IntegrationSlackNotificationModal from 'dialogs/integration-slack-notification-modal';
import IntegrationPhoneModal from 'dialogs/integration-phone-modal';
import IntegrationWebChatModal from 'dialogs/integration-web-chat-modal';
import {
    SlackLogo,
    SettingsLogo,
    PhoneIntegrationLogo,
    V3IntegrationLogo,
    IntegrationDefaultLogo,
    PowerCircle,
    Edit,
} from 'assets/svg';
import { apiURL, axiosClient } from 'service';
import Spinner from 'components/spinner';
import notify from 'utils/notification';
import { IntegrationStatus, IntegrationAppNames, ModalTypes } from 'utils/constants';

interface IIntegrationAppItem {
    name: string;
    source: string;
    category: string;
    url: string;
    icon: string;
    description: string;
    id?: string;
    account_logo_url?: string;
    status?: string;
    token?: string;
    options?: any;
}

interface CategoryItem {
    category: string;
    categoryItems: IIntegrationAppItem[];
}

export default function Customers() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const slack_modal = queryParams.get('slack_modal');

    const [integrationSlackModalVisible, setIntegrationSlackModalVisible] = useState(false);
    const [integrationPhoneModalVisible, setIntegrationPhoneModalVisible] = useState(false);
    const [integrationWebChatModalVisible, setIntegrationWebChatModalVisible] = useState(false);
    const [selectedAppItem, setSelectedAppItem] = useState<IIntegrationAppItem | null>(null);
    const [integrationPhone, setIntegrationPhone] = useState<string | undefined>('');
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState<CategoryItem[]>([]);
    const [modalType, setModalType] = useState<string>('');

    const getEditItems = (appItem: IIntegrationAppItem): MenuProps['items'] => [
        {
            key: 'edit_integration',
            label: (
                <div
                    onClick={() => {
                        if (appItem.name === IntegrationAppNames.slack) {
                            setIntegrationSlackModalVisible(true);
                        } else if (appItem.name === IntegrationAppNames.webChat) {
                            setSelectedAppItem(appItem);
                            setIntegrationWebChatModalVisible(true);
                            setModalType(ModalTypes.edit);
                        } else {
                            return;
                        }
                    }}
                    className="flex items-center text-greyscale-900 text-xs"
                >
                    <Edit />
                    <span className="ml-2">Edit Integration</span>
                </div>
            ),
        },
        {
            key: 'deactivate',
            label: (
                <div
                    onClick={() => handleDeactivateIntegration(appItem.id as string)} // Pass integrationId to the handler
                    className="flex items-center text-red-600 text-xs"
                >
                    <PowerCircle />
                    <span className="ml-2">Deactivate</span>
                </div>
            ),
        },
    ];

    const handleDeactivateIntegration = async (integrationId: string) => {
        setIsLoading(true);
        try {
            await axiosClient.delete(`${apiURL.integrations}/${integrationId}`);

            getIntegrations();
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error deactivate integration' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleConnectClick = async (appName: string) => {
        if (appName === IntegrationAppNames.v3) {
            return;
        } else if (appName === IntegrationAppNames.slack) {
            const response = await axiosClient.get(apiURL.slackAuthorize);

            if (response.status === 200) {
                window.location.href = response.data.redirect_url;
            }
        } else if (appName === IntegrationAppNames.webChat) {
            setModalType(ModalTypes.add);
            setSelectedAppItem(null);
            setIntegrationWebChatModalVisible(true);
        }
    };

    const handleContinueClick = async (appName: string) => {
        if (appName === IntegrationAppNames.v3) {
            return;
        } else if (appName === IntegrationAppNames.slack) {
            setIntegrationSlackModalVisible(true);
        } else if (appName === IntegrationAppNames.webChat) {
            setModalType(ModalTypes.add);
            setSelectedAppItem(null);
            setIntegrationWebChatModalVisible(true);
        }
    };

    const getIntegrations = async () => {
        setIsLoading(true);
        try {
            const res = await axiosClient.get(apiURL.integrations);

            const transformedArray = res?.data?.reduce((acc: CategoryItem[], item: IIntegrationAppItem) => {
                let categoryObj = acc.find((obj) => obj.category === item.category);

                if (!categoryObj) {
                    categoryObj = {
                        category: item.category,
                        categoryItems: [],
                    };
                    acc.push(categoryObj);
                }

                categoryObj.categoryItems.push(item);

                return acc;
            }, []);

            setCategories(transformedArray || []);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching customers' });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getIntegrations();
    }, []);

    useEffect(() => {
        if (!slack_modal) return;
        setIntegrationSlackModalVisible(true);
    }, [slack_modal]);

    return (
        <div className="flex flex-col flex-1">
            <Breadcrumb
                items={[
                    {
                        title: 'Integrations',
                    },
                ]}
            />
            {categories?.map((categoryItem) => (
                <>
                    <div className="tracking-0.28px text-greyscale-500 text-sm font-medium leading-5 mt-7 mb-6">
                        {categoryItem?.category?.toUpperCase()}
                    </div>
                    <Row gutter={[24, 24]}>
                        {categoryItem?.categoryItems?.map((appItem: IIntegrationAppItem) => {
                            return (
                                <Col key={appItem.id} sm={24} xl={8}>
                                    <Card
                                        styles={{ body: { height: '100%', padding: 0 } }}
                                        className="h-full p-0"
                                        bordered={false}
                                    >
                                        <div className="flex h-full p-6 relative">
                                            <div>
                                                {appItem.name === IntegrationAppNames.slack ? (
                                                    <SlackLogo />
                                                ) : appItem.name === IntegrationAppNames.phone ? (
                                                    <PhoneIntegrationLogo />
                                                ) : appItem.name === IntegrationAppNames.v3 ? (
                                                    <V3IntegrationLogo />
                                                ) : (
                                                    <IntegrationDefaultLogo />
                                                )}

                                                {appItem.name === IntegrationAppNames.phone ? (
                                                    <div
                                                        onClick={() => {
                                                            setIntegrationPhoneModalVisible(true);
                                                            setIntegrationPhone(appItem?.options?.phone_number);
                                                        }}
                                                        className="cursor-pointer text-center rounded-lg bg-green-600 px-4 py-2 text-white text-sm font-bold mt-2"
                                                    >
                                                        Active
                                                    </div>
                                                ) : appItem.status === IntegrationStatus.active ? (
                                                    <div className="text-center rounded-lg bg-green-600 px-4 py-2 text-white text-sm font-bold mt-2">
                                                        Active
                                                    </div>
                                                ) : appItem.status === IntegrationStatus.pending ? (
                                                    <div
                                                        onClick={() => handleContinueClick(appItem.name)}
                                                        className="cursor-pointer rounded-lg bg-red-600 px-4 py-2 text-white text-sm font-bold mt-2"
                                                    >
                                                        Continue
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={() => handleConnectClick(appItem.name)}
                                                        className="cursor-pointer text-center rounded-lg border border-greyscale-300 border-solid px-4 py-2 text-primary text-sm font-bold mt-2"
                                                    >
                                                        Connect
                                                    </div>
                                                )}
                                            </div>
                                            <div className="ml-6">
                                                <div className="flex items-center mb-2">
                                                    <div className="text-greyscale-900 font-semibold text-base mr-2">
                                                        {appItem?.name}
                                                    </div>
                                                    {appItem.id && appItem.name !== IntegrationAppNames.phone && (
                                                        <div className="flex items-center">
                                                            <div
                                                                className={`${
                                                                    appItem.status === IntegrationStatus.active
                                                                        ? 'bg-green-600'
                                                                        : 'bg-red-600'
                                                                } w-[11px] h-[11px] rounded-[100px] mr-1`}
                                                            />
                                                            <div className="text-greyscale-500 text-2xs font-semibold">
                                                                {appItem.status === IntegrationStatus.active
                                                                    ? 'Working'
                                                                    : 'Pending channel selection'}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="text-greyscale-500 text-sm">{appItem.description}</div>
                                            </div>
                                            {appItem.id && appItem.name !== IntegrationAppNames.phone ? (
                                                <div className="cursor-pointer absolute top-6 right-6">
                                                    <Dropdown
                                                        menu={{
                                                            items: getEditItems(appItem),
                                                        }}
                                                        placement="bottomRight"
                                                    >
                                                        <SettingsLogo />
                                                    </Dropdown>
                                                </div>
                                            ) : null}
                                        </div>
                                    </Card>
                                </Col>
                            );
                        })}
                    </Row>
                </>
            ))}
            <IntegrationSlackNotificationModal
                visible={integrationSlackModalVisible}
                onClose={() => setIntegrationSlackModalVisible(false)}
                getNewList={() => getIntegrations()}
            />
            <IntegrationPhoneModal
                visible={integrationPhoneModalVisible}
                onClose={() => setIntegrationPhoneModalVisible(false)}
                integrationPhone={integrationPhone}
            />
            <IntegrationWebChatModal
                visible={integrationWebChatModalVisible}
                onClose={() => setIntegrationWebChatModalVisible(false)}
                webChatOptions={selectedAppItem?.options}
                activeAuthToken={selectedAppItem?.token}
                integrationId={selectedAppItem?.id as string}
                account_logo_url={selectedAppItem?.account_logo_url as string}
                modalType={modalType}
                getNewList={() => getIntegrations()}
            />
            <Spinner visible={isLoading} />
        </div>
    );
}
