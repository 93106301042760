import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Input, Spin } from 'antd';
import { CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';

// components
import { apiURL, axiosClient } from 'service';
import notify from 'utils/notification';
import { SlackLogo } from 'assets/svg';
import { IntegrationStatus } from 'utils/constants';

interface IProps {
    visible: boolean;
    onClose: () => void;
    getNewList: () => void;
}

interface IChannelItem {
    channel_type: string;
    name: string;
    label: string;
    id: string | undefined | number;
    isValid: boolean;
    isLoading: boolean;
}

const IntegrationSlackNotificationModal: React.FC<IProps> = ({ visible, onClose, getNewList }) => {
    const navigate = useNavigate();

    const [channels, setChannels] = useState<IChannelItem[]>([
        {
            channel_type: 'conversation',
            label: 'Conversation',
            name: '',
            id: undefined,
            isValid: false,
            isLoading: false,
        },
        { channel_type: 'complaint', label: 'Complaint', name: '', id: undefined, isValid: false, isLoading: false },
    ]);
    const [slackIntegrationId, setSlackIntegrationId] = useState(undefined);
    const [isSaveLoading, setIsSaveLoading] = useState(false);

    const handleFindChannel = async (index: number) => {
        setChannels((prevChannels) => {
            const newChannels = [...prevChannels];
            newChannels[index].isLoading = true;
            return newChannels;
        });
        try {
            const response = await axiosClient.get(`${apiURL.findSlackChannel}?channel_name=${channels[index].name}`);
            if (response.data.channel.is_channel) setSlackIntegrationId(response.data.integration_id);
            setChannels((prevChannels) => {
                const newChannels = [...prevChannels];
                if (response.data.channel.is_channel) {
                    newChannels[index].isValid = true;
                    newChannels[index].id = response.data.channel.id;
                } else {
                    newChannels[index].isValid = false;
                }
                newChannels[index].isLoading = false;
                return newChannels;
            });
        } catch (error: any) {
            notify({ type: 'error', message: error?.response?.data?.error || 'Error fetching channel' });
            setChannels((prevChannels) => {
                const newChannels = [...prevChannels];
                newChannels[index].isValid = false;
                newChannels[index].isLoading = false;
                return newChannels;
            });
        }
    };

    const handleSaveClick = async () => {
        setIsSaveLoading(true);
        const payload = {
            options: {
                channels: channels
                    .filter((item: IChannelItem) => item.name)
                    .map((channel: IChannelItem) => {
                        if (channel.name) {
                            return {
                                channel_type: channel.channel_type,
                                channel: channel.name,
                                channel_id: channel.id,
                            };
                        }
                    }),
            },
            status: IntegrationStatus.active,
        };

        try {
            await axiosClient.put(`${apiURL.integrations}/${slackIntegrationId}`, payload);
            onClose();
            getNewList();
            navigate('/integrations');
            notify({ type: 'success', message: 'Integration is successful' });
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error saving channel' });
        } finally {
            setIsSaveLoading(false);
        }
    };

    const handleInputChange = (index: number, value: string) => {
        setChannels((prevChannels) => {
            const newChannels = [...prevChannels];
            newChannels[index].name = value;
            return newChannels;
        });
    };

    return (
        <Modal
            centered
            className="w-[413px]"
            classNames={{ body: 'flex flex-1 flex-col p-6' }}
            closeIcon={<CloseCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="text-xl font-semibold text-grey-scale-900 mb-6">Slack Notifications</div>
            <div className="flex flex-1 flex-col">
                <div className="flex">
                    <div>
                        <SlackLogo />
                    </div>

                    <div className="ml-6 text-sm text-greyscale-500 mb-4 ">
                        To receive notifications, you need to select a Slack channel from the options below.
                    </div>
                </div>
                {channels.map((channel, index) => (
                    <div key={index} className="mt-6" id="channel-row">
                        <div className="text-sm text-grey-scale-900 font-semibold mb-2">{channel.label}</div>

                        <Input
                            value={channel.name}
                            onChange={(e) => handleInputChange(index, e.target.value)}
                            placeholder="Channel"
                            onBlur={() => handleFindChannel(index)}
                            suffix={channel.isLoading && <Spin indicator={<LoadingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} spin />} />}
                        />
                    </div>
                ))}
                <div className="mt-6">
                    <Button
                        disabled={!channels.some((channel) => channel.isValid)}
                        className="flex items-center justify-center flex-1 w-full"
                        type="primary"
                        onClick={handleSaveClick}
                        loading={isSaveLoading}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default IntegrationSlackNotificationModal;
