import React, { ReactNode } from 'react';
import providers from 'context/providers';

interface AppProviderProps {
    children: ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
    let result: any = children;
    providers.forEach((Wrapper) => (result = <Wrapper>{result}</Wrapper>));
    return result;
};

export default AppProvider;
