import React, { useState } from 'react';
// components
import { Button, Col, Form, Input, Row, Switch, Typography } from 'antd';

// styles
import { BuckLogo } from 'assets/svg';
import WelcomeBanner from 'components/welcome-banner';

const { Title, Text } = Typography;

// types
interface SignUpFormValues {
    email: string;
    password: string;
}

const SignUp: React.FC = () => {
    const [form] = Form.useForm();
    const [loading] = useState(false);

    const onSubmit = (values: SignUpFormValues) => {
        console.log('values', values);
    };

    return (
        <div className="flex h-screen">
            <Row className="flex-1">
                <Col className="flex flex-col items-center xl:items-start justify-between p-4 xl:p-12" span={12}>
                    <BuckLogo className="h-12 w-min" />
                    <section className="flex flex-col mx-auto max-w-lg w-full text-center gap-6">
                        <div className="flex flex-col gap-2.5">
                            <Title level={2}>Sign in</Title>
                            <Text className="text-greyscale-600">Sign in for dashboard and platform access.</Text>
                        </div>
                        <Form form={form} initialValues={{ remember: true }} onFinish={onSubmit}>
                            <Form.Item
                                name="email"
                                rules={[{ required: true, message: 'Please input your Username!' }]}
                            >
                                <Input prefix={<span className="icon-user"></span>} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input
                                    prefix={<span className="icon-lock"></span>}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <div className="flex justify-between">
                                <div>
                                    <Form.Item name="remember" valuePropName="checked" noStyle>
                                        <Switch defaultChecked />{' '}
                                        <label className="ml-3 text-grayscale-900 text-sm font-semibold cursor-pointer">
                                            Remember me
                                        </label>
                                    </Form.Item>
                                </div>
                                <a
                                    className="text-primary font-semibold hover:text-primary-500 hover:underline"
                                    href="/forgot-password"
                                >
                                    Forgot password
                                </a>
                            </div>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                    loading={loading}
                                >
                                    Log in
                                </Button>
                            </Form.Item>
                        </Form>
                    </section>
                    <footer className="flex justify-between w-full">
                        <a
                            href="https://buck.ai/terms"
                            className="text-primary hover:text-primary-500 hover:underline font-semibold"
                        >
                            Privacy Policy
                        </a>
                        <span className="text-greyscale-600 font-semibold text-sm">Copyright © 2022</span>
                    </footer>
                </Col>
                <WelcomeBanner />
            </Row>
        </div>
    );
};

export default SignUp;
