import React, { useEffect, useState } from 'react';

// components
import { Button } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import CompanyInformation from './playbook-components/company-information';
import ServicePolicies from './playbook-components/service-policies';
import Questions from './playbook-components/questions';
import Messages from './playbook-components/messages';
import Fees from './playbook-components/fees';
import ServiceableTypes from './playbook-components/serviceable-types';

// assets
import { SaveLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';

const Capacity = () => {
    const [visibleMenus, setVisibleMenus] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [playbook, setPlaybook] = useState<any>({});

    const getPlaybook = () => {
        axiosClient
            .get(apiURL.playbook)
            .then((res) => {
                setPlaybook(res?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                notify({ type: 'error', message: err?.message || 'Failed to fetch playbook' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleSaveClick = () => {
        axiosClient
            .put(apiURL.playbook, playbook)
            .then(() => {
                setIsLoading(false);
                notify({ type: 'success', message: 'Playbook updated successfully' });
                getPlaybook();
            })
            .catch((err) => {
                setIsLoading(false);
                notify({ type: 'error', message: err?.message || 'Failed to fetch playbook' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getPlaybook();
    }, []);

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="flex flex-col mb-4 items-end">
                <Button type="primary" onClick={handleSaveClick} icon={<SaveLogo />}>
                    Save Changes
                </Button>
            </div>
            <Messages messages={playbook?.messages} setPlaybook={setPlaybook} />
            <CompanyInformation
                companyInformation={playbook?.company_information}
                visibleMenus={visibleMenus}
                setPlaybook={setPlaybook}
                setVisibleMenus={setVisibleMenus}
            />
            <Questions
                questions={playbook?.questions}
                setPlaybook={setPlaybook}
                visibleMenus={visibleMenus}
                setVisibleMenus={setVisibleMenus}
            />
            <Fees
                fees={playbook?.fees}
                setPlaybook={setPlaybook}
                visibleMenus={visibleMenus}
                setVisibleMenus={setVisibleMenus}
            />
            <ServicePolicies
                servicePolicies={playbook?.service_policies}
                setPlaybook={setPlaybook}
                visibleMenus={visibleMenus}
                setVisibleMenus={setVisibleMenus}
            />
            <ServiceableTypes
                playbook={playbook}
                setPlaybook={setPlaybook}
                visibleMenus={visibleMenus}
                setVisibleMenus={setVisibleMenus}
            />
            <div className="flex flex-col mb-4 items-end">
                <Button className="mb-6" type="primary" onClick={handleSaveClick} icon={<SaveLogo />}>
                    Save Changes
                </Button>
            </div>
            <Spinner visible={isLoading} />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Playbook',
        href: '/settings/playbook',
    },
];

export default Capacity;
