import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';

// components
import { Button, Card, Col, Row, Input, Dropdown } from 'antd';
import Label from 'components/label';
import { PhoneLogo } from 'assets/svg';
import Spinner from 'components/spinner';
import CustomerLocationModal from 'dialogs/customer-location-modal/customer-location-modal';
import { ContactSource } from 'utils/constants';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import formatUSAPhone from 'utils/format-usa-phone';

interface IContact {
    id: string;
    customer_id: string;
    source: string;
    value: string;
    created_at: string;
    updated_at: string;
}

interface IConversations {
    id: string;
    account_id: string;
    integration_id: string;
    contact_id: string;
    customer_id: string;
    priority: string;
    reason: string;
    source: string;
    last_sender: string;
    status: string;
    external_id: string;
    messages_count: number;
    created_at: string;
    updated_at: string;
}

interface ICustomer {
    id?: string;
    name: string;
    locations: any[];
    contacts: IContact[];
    conversations: IConversations[];
    appointments: any[];
    conversations_count: number;
    bookings_count: number;
}

export interface ILocationItem {
    id: string;
    unit: null;
    street: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
    longitude: number;
    latitude: number;
    full_address: string;
}

interface IContactItem {
    id: string;
    source: string;
    value: string;
}

export default function CustomerDetail() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [map, setMap] = useState<any>(null);
    const [customerLocationModalVisible, setCustomerLocationModalVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdited, setIsEdited] = useState(false);
    const [location, setLocation] = useState<any>([]);
    const [address, setAddress] = useState<any>('');
    const [locations, setLocations] = useState<ILocationItem[]>([]);
    const [contactPhones, setContactPhones] = useState<IContactItem[]>([]);
    const [contactEmails, setContactEmails] = useState<IContactItem[]>([]);
    const [customer, setCustomer] = useState<ICustomer>({
        name: '',
        locations: [],
        contacts: [],
        conversations: [],
        appointments: [],
        conversations_count: 0,
        bookings_count: 0,
    });

    const handleCustomerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        setCustomer({
            ...customer,
            name: event.target.value,
        });
    };

    const handleSaveClick = () => {
        setIsLoading(true);
        axiosClient
            .put(`${apiURL.customers}/${id}`, {
                customer: {
                    name: customer.name,
                },
            })
            .then((updatedCustomer) => {
                setCustomer(updatedCustomer.data);
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.toString() });
            })
            .finally(() => {
                setIsLoading(false);
                setIsEdited(false);
            });
    };

    useEffect(() => {
        if (id) {
            setIsLoading(true);
            Promise.all([
                axiosClient.get(`${apiURL.customers}/${id}`),
                axiosClient.get(`${apiURL.customers}/${id}/locations`),
                axiosClient.get(`${apiURL.customers}/${id}/contacts`),
            ])
                .then(([customer, locations, contacts]) => {
                    setCustomer(customer?.data);
                    setLocations(locations?.data?.data);
                    setContactPhones(
                        contacts?.data?.data.filter((item: IContactItem) => item.source === ContactSource.PHONE),
                    );
                    setContactEmails(
                        contacts?.data?.data.filter((item: IContactItem) => item.source === ContactSource.EMAIL),
                    );
                })
                .catch((error) => {
                    notify({ type: 'error', message: error?.toString() });
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [id]);

    useEffect(() => {
        if (!locations.length) return;
        const { latitude, longitude, full_address } = locations[0];

        setLocation([latitude, longitude]);
        setAddress(full_address);
    }, [locations]);

    let customerNameDisplayLetters;
    if (customer?.name) {
        const words = customer?.name.split(' ');
        if (words.length === 1) {
            // If there's only one word, take the first two letters
            customerNameDisplayLetters = customer?.name.substring(0, 2);
        } else {
            // take first letters of words
            customerNameDisplayLetters = words[0][0] + (words[1] ? words[1][0] : '');
        }
    }

    return (
        <Row className="flex flex-col flex-1">
            <Row className="flex mb-4">
                <Col sm={24} lg={24} xl={24}>
                    <Card bordered={false}>
                        <Row gutter={16}>
                            <Col sm={24} lg={8} xl={8}>
                                <div className="max-w-80">
                                    <div className="flex mb-6">
                                        <div className="flex w-10 h-10 justify-center items-center flex-shrink-0 rounded-full bg-primary-300 mr-4">
                                            <p className="text-white text-center text-sm font-bold uppercase">
                                                {customerNameDisplayLetters}
                                            </p>
                                        </div>
                                        <div className="flex text-base text-greyscale-600 justify-center items-center">
                                            {contactEmails?.[0]?.value}
                                        </div>
                                    </div>
                                    <div className="mb-6">
                                        <Label>Name Surname</Label>
                                        <Input
                                            value={customer?.name}
                                            onChange={handleCustomerNameChange}
                                            className="mt-1"
                                        />
                                    </div>
                                    <div className="flex">
                                        <div className="flex flex-1 items-center px-4 py-2 bg-greyscale-100 rounded-10 mb-14">
                                            <PhoneLogo />
                                            <p className="m-0 ml-2">{formatUSAPhone(contactPhones?.[0]?.value)}</p>
                                        </div>
                                        {contactPhones.length - 1 > 0 && (
                                            <Dropdown
                                                dropdownRender={() => (
                                                    <div className="rounded-10 bg-white shadow-dropdown-menu w-52">
                                                        <div className="pt-6 px-6 text-sm text-black font-medium">
                                                            Phone Numbers
                                                        </div>
                                                        {contactPhones.map((item, i) => (
                                                            <div
                                                                key={item.id}
                                                                className={`${
                                                                    i !== contactPhones.length - 1 &&
                                                                    'border-solid border-b border-x-0 border-y-0 border-greyscale-200'
                                                                } py-4 px-6 text-sm text-greyscale-900`}
                                                            >
                                                                {formatUSAPhone(item.value)}
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            >
                                                <div className="border-greyscale-200 border-solid rounded-10 flex items-center justify-center w-10 h-10 ml-6 text-primary font-bold font-xs">
                                                    +{contactPhones.length - 1}
                                                </div>
                                            </Dropdown>
                                        )}
                                    </div>
                                    <div>
                                        <Button
                                            className="flex items-center justify-center flex-1 w-full"
                                            type="primary"
                                            disabled={!isEdited}
                                            onClick={handleSaveClick}
                                        >
                                            Save Changes
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={24} lg={16} xl={16}>
                                <div className="flex flex-col flex-1 h-full">
                                    <div id="map" className="flex flex-1 mb-6">
                                        {location.length && (
                                            <MapContainer
                                                style={{ flex: 1 }}
                                                center={location}
                                                zoom={13}
                                                scrollWheelZoom={false}
                                                ref={setMap}
                                            >
                                                <TileLayer
                                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                />
                                                <Marker position={location}>
                                                    <Popup>{address}</Popup>
                                                </Marker>
                                            </MapContainer>
                                        )}
                                    </div>
                                    <div className="flex">
                                        {locations.map(
                                            (location, ind) =>
                                                ind < 3 && (
                                                    <div
                                                        onClick={() => {
                                                            map?.setView([location.latitude, location.longitude]);
                                                            setLocation([location.latitude, location.longitude]);
                                                            setAddress(location.full_address);
                                                        }}
                                                        key={location.id}
                                                        className="max-w-64 border-greyscale-200 border-solid rounded-10 flex flex-1 flex-col p-3 mr-6 font-bold font-xs"
                                                    >
                                                        <div className="text-greyscale-500 font-medium text-xs">
                                                            {location?.city}
                                                        </div>
                                                        <div className="font-normal text-sm max-w-56 text-ellipsis overflow-hidden truncate relative">
                                                            {location?.full_address}
                                                        </div>
                                                    </div>
                                                ),
                                        )}
                                        {locations.length > 3 && (
                                            <div
                                                onClick={() => setCustomerLocationModalVisible(true)}
                                                className="border-greyscale-200 border-solid rounded-10 flex items-center justify-center px-6 text-primary font-bold font-xs"
                                            >
                                                +{locations.length - 3}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="flex flex-1" gutter={16}>
                <Col sm={24} lg={12} xl={12}>
                    <Card bordered={false}>
                        <div className="flex justify-between">
                            <div className="max-w-80">
                                <div className="text-primary-900 font-bold text-lg mb-3">Conversations</div>
                                <div className="mb-3 text-primary-600 font-normal">
                                    You can see all conversation details under the conversations menu
                                </div>
                                <Button onClick={() => navigate('/conversations')}>See All</Button>
                            </div>
                            <div className="flex items-center font-bold text-5xl text-primary-900">
                                {customer?.conversations_count}
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col sm={24} lg={12} xl={12}>
                    <Card bordered={false}>
                        <div className="flex justify-between">
                            <div className="max-w-80">
                                <div className="text-primary-900 font-bold text-lg mb-3">Bookings</div>
                                <div className="mb-3 text-primary-600 font-normal">
                                    You can see all booking details under the bookings menu
                                </div>
                                <Button onClick={() => navigate('/bookings')}>See All</Button>
                            </div>
                            <div className="flex items-center font-bold text-5xl text-primary-900">
                                {customer?.bookings_count}
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Spinner visible={isLoading} />
            <CustomerLocationModal
                visible={customerLocationModalVisible}
                onClose={() => setCustomerLocationModalVisible(false)}
                onSave={() => {}}
                locations={locations.filter((item, index) => index > 2)}
            />
        </Row>
    );
}
