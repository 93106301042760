const apiURL = {
    baseUrl: process.env.REACT_APP_API_URL,
    generalUrl: process.env.REACT_APP_API_GENERAL_URL,
    accounts: '/accounts',
    conversations: '/conversations',
    conversationCustomers: '/conversations/latest/customers',
    getUser: '/users/',
    signIn: '/users/authenticate',
    acceptInvitation: '/users/invite',
    customers: '/customers',
    bookings: '/bookings',
    complaints: '/complaints',
    integrations: '/integrations',
    insights: '/insights',
    findSlackChannel: '/integrations/slack/channels',
    slackAuthorize: '/integrations/slack/authorize',
    passwordReset: '/users/password_reset',
    users: '/users',
    memberships: '/memberships',
    arrivalWindows: '/arrival_windows',
    zones: '/zones',
    capacities: '/capacities',
    services: '/services',
    fsmOptions: '/fsm_options',
    capacitiesBatch: '/capacities/batch',
    playbook: '/playbook',
};

export default apiURL;
