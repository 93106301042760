import React, { useState } from 'react';

// components
import { Col, Form, Input, Row, Typography, Result, Button } from 'antd';
import WelcomeBanner from 'components/welcome-banner';
import Spinner from 'components/spinner';

// styles
import { BuckLogo } from 'assets/svg';

// helpers
import notify from 'utils/notification';
import { apiURL, axiosClient } from 'service';
const { Title, Text } = Typography;

// types
interface IForgotPassword {
    email: string;
}

const ForgotPassword: React.FC = () => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);

    const onSubmit = (values: IForgotPassword) => {
        setIsLoading(true);
        axiosClient
            .post(apiURL.passwordReset, {
                user: {
                    email: values.email,
                },
            })
            .then(() => {
                setShowSuccessScreen(true);
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.toString() });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <div className="flex h-screen">
            <Row className="flex-1">
                <Col className="flex flex-col flex-1 xl:items-start justify-between p-4 xl:p-12" xl={12}>
                    <BuckLogo className="h-12 w-min" />
                    {!showSuccessScreen ? (
                        <section className="flex flex-col mx-auto max-w-lg w-full text-center gap-6">
                            <div className="flex flex-col gap-2.5">
                                <Title level={2}>Forgot Password</Title>
                                <Text className="text-greyscale-600">
                                    Enter your email address and we will send you a link to reset your password.
                                </Text>
                            </div>
                            <Form form={form} initialValues={{ remember: true }} onFinish={onSubmit}>
                                <Form.Item
                                    className="mb-4"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your Username!' }]}
                                >
                                    <Input prefix={<span className="icon-user"></span>} placeholder="Email" />
                                </Form.Item>
                                <Form.Item>
                                    <Input type="submit" value="Send Email" />
                                </Form.Item>
                            </Form>
                        </section>
                    ) : (
                        <Result
                            status="success"
                            title={`We sent a verification link to ${form.getFieldValue('email')}`}
                            subTitle="Please check your mailbox for verification link."
                            extra={[
                                <Button onClick={() => setShowSuccessScreen(false)} type="primary" key="sendMail">
                                    Send mail again
                                </Button>,
                            ]}
                        />
                    )}
                    <footer className="flex justify-between w-full">
                        <a
                            href="https://buck.ai/terms"
                            className="text-primary hover:text-primary-500 hover:underline font-semibold"
                        >
                            Privacy Policy
                        </a>
                        <span className="text-greyscale-600 font-semibold text-sm">Copyright © 2022</span>
                    </footer>
                </Col>
                <WelcomeBanner />
            </Row>
            <Spinner visible={isLoading} />
        </div>
    );
};

export default ForgotPassword;
