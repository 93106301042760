import axios from 'axios';
import apiURL from 'service/apiURL';

const createAxiosClient = () => {
    const axiosClient = axios.create({
        baseURL: apiURL.baseUrl,
        headers: {
            Accept: 'application/json',
        },
        validateStatus: (status) => status >= 200 && status < 300,
    });

    axiosClient.interceptors.response.use(({ data, status }) => ({ data, status }));
    return axiosClient;
};

const axiosClient = createAxiosClient();

export default axiosClient;
