import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import Breadcrumb from 'components/breadcrumb';
import { Button, TableColumnsType, ConfigProvider, Table, Input } from 'antd';
import Spinner from 'components/spinner';

// assets
import { SaveLogo, MinusLogo, PlusLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import dayjs from 'dayjs';
import { convertTimeToAccountTimezone, formatType } from 'utils/formatter';
import EmptyStateCard from 'components/empty-state-card';

interface ICapacityItem {
    id: string;
    trade: {
        id: string;
        name: string;
    };
    service_type: {
        id: number;
        name: string;
    };
    arrival_window: {
        id: string;
        title: string;
        from_hour: string;
        to_hour: string;
    };
    sunday: number;
    monday: number;
    tuesday: number;
    wednesday: number;
    thursday: number;
    friday: number;
    saturday: number;
}

type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';
interface TableDataProps {
    day: DayOfWeek;
    record: ICapacityItem;
    activeItemId: string;
    onChange: (serviceId: string, day: string, value: string) => void;
    onBlur: () => void;
    onPlusClick: (serviceId: string, day: string) => void;
    onMinusClick: (serviceId: string, day: string) => void;
    onItemClick: () => void;
}

const TableData = ({
    day,
    record,
    activeItemId,
    onChange,
    onBlur,
    onPlusClick,
    onMinusClick,
    onItemClick,
}: TableDataProps) => {
    if (record.id === activeItemId) {
        return (
            <Input
                type="number"
                onChange={(e) => onChange(record.id, 'monday', e.target.value)}
                onBlur={onBlur}
                value={record?.monday}
            />
        );
    }
    return (
        <div className="flex items-center justify-between">
            <Button
                onClick={() => onMinusClick(record.id, day)}
                className="w-7 h-7 p-0 rounded-full border-greyscale-250 flex items-center justify-center"
            >
                <MinusLogo />
            </Button>
            <div className="cursor-pointer text-greyscale-500 text-xs" onClick={onItemClick}>
                {record[day]}
            </div>
            <Button
                onClick={() => onPlusClick(record.id, day)}
                className="w-7 h-7 p-0 rounded-full border-greyscale-250 flex items-center justify-center"
            >
                <PlusLogo />
            </Button>
        </div>
    );
};

const Capacity = () => {
    const navigate = useNavigate();

    const [bodyVisible, setBodyVisible] = useState<number[]>([]);
    const [capacities, setCapacities] = useState<[string, ICapacityItem][]>([]);
    const [activeTableData, setActiveTableData] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);

    const getCapacites = () => {
        setIsLoading(true);
        axiosClient
            .get(apiURL.capacities)
            .then((res) => {
                const groupedCapacities = new Map();

                res?.data?.forEach((item: any) => {
                    const key = item?.trade?.name + ' / ' + item?.service_type?.name;

                    if (groupedCapacities.has(key)) {
                        const current = groupedCapacities.get(key);
                        current.push(item);
                        groupedCapacities.set(key, current);
                    } else {
                        groupedCapacities.set(key, [item]);
                    }
                });

                setCapacities(Array.from(groupedCapacities));
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handlePlusClick = (serviceId: string, day: string) => {
        setCapacities((prev: any) =>
            prev.map(([key, values]: any) => {
                return [
                    key,
                    values.map((item: any) => {
                        if (item.id === serviceId) {
                            return { ...item, [day]: item[day] + 1 };
                        }
                        return item;
                    }),
                ];
            }),
        );
    };

    const handleMinusClick = (serviceId: string, day: string) => {
        setCapacities((prev: any) =>
            prev.map(([key, values]: any) => {
                return [
                    key,
                    values.map((item: any) => {
                        if (item.id === serviceId && item[day] > 0) {
                            return { ...item, [day]: item[day] - 1 };
                        }
                        return item;
                    }),
                ];
            }),
        );
    };

    const handleTableDataChange = (serviceId: string, day: string, value: string) => {
        setCapacities((prev: any) =>
            prev.map(([key, values]: any) => {
                return [
                    key,
                    values.map((item: any) => {
                        if (item.id === serviceId) {
                            return { ...item, [day]: parseInt(value) };
                        }
                        return item;
                    }),
                ];
            }),
        );
    };

    const handleSaveClick = async (data: ICapacityItem[]) => {
        if (!data.length) {
            return;
        }
        setIsLoading(true);
        const payload = {
            capacities: data.map((item) => {
                return {
                    id: item.id,
                    attributes: {
                        monday: item.monday,
                        tuesday: item.tuesday,
                        wednesday: item.wednesday,
                        thursday: item.thursday,
                        friday: item.friday,
                        saturday: item.saturday,
                        sunday: item.sunday,
                    },
                };
            }),
        };

        try {
            await axiosClient.put(apiURL.capacitiesBatch, payload);
            notify({ type: 'success', message: 'Capacity updated successfully' });
        } catch (error) {
            console.error('error', error);
            notify({ type: 'error', message: error?.toString() });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getCapacites();
    }, []);

    const columns: TableColumnsType<ICapacityItem> = [
        {
            title: 'Slots',
            width: 100,
            dataIndex: 'slots',
            fixed: 'left',
            render: (text, record: ICapacityItem) => {
                const fromHour =
                    convertTimeToAccountTimezone(dayjs(record?.arrival_window?.from_hour, 'HH:mm'), formatType.time) ||
                    '';
                const toHour =
                    convertTimeToAccountTimezone(dayjs(record?.arrival_window?.to_hour, 'HH:mm'), formatType.time) ||
                    '';
                return (
                    <div className="flex items-center justify-center flex-col border border-solid lowercase border-greyscale-250 bg-greyscale-50 text-greyscale-900 py-2 px-2.5 rounded-20">
                        <div>{fromHour}</div>
                        <div>{toHour}</div>
                    </div>
                );
            },
        },
        {
            title: 'Mon',
            width: 90,
            dataIndex: 'monday',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="monday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Tue',
            width: 90,
            dataIndex: 'tuesday',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="tuesday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Wed',
            width: 90,
            dataIndex: 'wednesday',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="wednesday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Thu',
            width: 90,
            dataIndex: 'thursday',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="thursday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Fri',
            width: 90,
            dataIndex: 'friday',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="friday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Sat',
            width: 90,
            dataIndex: 'saturday',
            className: 'bg-greyscale-50',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="saturday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
        {
            title: 'Sun',
            fixed: 'right',
            width: 90,
            dataIndex: 'sunday',
            className: 'bg-greyscale-50',
            render: (text, record: ICapacityItem) => {
                return (
                    <TableData
                        day="sunday"
                        activeItemId={activeTableData}
                        record={record}
                        onChange={handleTableDataChange}
                        onBlur={() => setActiveTableData('')}
                        onItemClick={() => {
                            setActiveTableData(record.id);
                        }}
                        onPlusClick={handlePlusClick}
                        onMinusClick={handleMinusClick}
                    />
                );
            },
        },
    ];

    return (
        <div className="flex flex-col flex-1">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div>
                {capacities?.length === 0 && isLoading ? (
                    <Spinner visible={isLoading} />
                ) : capacities?.length > 0 ? (
                    capacities.map((item: any, index: number) => (
                        <div
                            key={index}
                            className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}
                        >
                            <div className="flex justify-between items-center mb-6">
                                <div>
                                    <div className="font-bold text-lg text-greyscale-900">{item[0]}</div>
                                    <div className="text-sx text-greyscale-900 font-normal">
                                        Adjust daily capacity and person count for each{' '}
                                        <span className="font-medium">Arrival Window</span>
                                    </div>
                                </div>
                                <Button
                                    onClick={() =>
                                        setBodyVisible((prev: number[]) => {
                                            if (prev.includes(index)) {
                                                return prev.filter((i) => i !== index);
                                            }
                                            return [...prev, index];
                                        })
                                    }
                                    className={`w-6 h-6 p-0 ${!bodyVisible.includes(index) && 'bg-greyscale-100'}`}
                                >
                                    {bodyVisible.includes(index) ? <MinusLogo /> : <PlusLogo />}
                                </Button>
                            </div>
                            {bodyVisible.includes(index) && (
                                <div>
                                    <div className="mb-6">
                                        <ConfigProvider
                                            prefixCls="capacity"
                                            theme={{
                                                cssVar: true,
                                                components: {
                                                    Table: {
                                                        headerColor: 'var(--greyscale-900)',
                                                        headerBg: 'var(--white)',
                                                        borderColor: 'var(--greyscale-250)',
                                                    },
                                                },
                                            }}
                                        >
                                            <Table columns={columns} dataSource={item[1]} pagination={false} bordered />
                                        </ConfigProvider>
                                    </div>
                                    <div className="flex justify-end">
                                        <Button
                                            onClick={() => handleSaveClick(item[1])}
                                            className="h-9"
                                            icon={<SaveLogo />}
                                            type="primary"
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                ) : (
                    <EmptyStateCard
                        emptyStateTitle="No Capacity Found"
                        emptyStateInfo="You need to activate services"
                        primaryButtonText="Go to Services"
                        primaryButtonAction={() => navigate('/settings/services')}
                    />
                )}
            </div>
            <Spinner visible={isLoading} />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Capacity',
        href: '/settings/capacity',
    },
];

export default Capacity;
