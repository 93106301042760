import { useContext } from 'react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AuthContext } from 'context';

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatType = {
    date: 'date',
    time: 'time',
};

const convertTimeToAccountTimezone = (time, type = formatType.date, customFormat) => {
    const {
        state: { user },
    } = useContext(AuthContext);

    const account_timezone = user?.memberships?.find((item) => item.account.id === user.active_account_id)?.account
        ?.timezone;

    // Default formats for date and time
    const defaultFormat = type === formatType.time ? 'hh:mm A' : 'D MMM h:mm A';

    // Apply the custom format if provided, otherwise use default
    const format = customFormat || defaultFormat;

    return dayjs(time).tz(account_timezone).format(format);
};

export { convertTimeToAccountTimezone };
