import React, { useState } from 'react';

// components
import { Button, Input } from 'antd';
// assets
import { AddCircleGreyLogo, MinusLogo, PlusLogo, Delete3Logo } from 'assets/svg';

interface QuestionsProps {
    visibleMenus: string[];
    questions: any[];
    setVisibleMenus: (prevState: any) => void;
    setPlaybook: (prevState: any) => void;
}

const pageKey = 'questions';

const Questions: React.FC<QuestionsProps> = ({ questions = [], setPlaybook, visibleMenus, setVisibleMenus }) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handleQuestionChange = (e: any, index: number) => {
        setPlaybook((prevState: any) => ({
            ...prevState,
            questions: prevState?.questions?.map((item: any, i: number) =>
                i === index ? { ...item, question: e.target.value } : item,
            ),
        }));
    };

    const handleAddQuestion = () => {
        setPlaybook((prevState: any) => ({
            ...prevState,
            questions: [
                ...(prevState?.questions || []),
                {
                    number: (prevState?.questions?.length || 0) + 1,
                    question: '',
                },
            ],
        }));
    };

    const handleDeleteQuestion = (index: number) => {
        setPlaybook((prevState: any) => ({
            ...prevState,
            questions: prevState?.questions?.filter((item: any, i: number) => i !== index),
        }));
        setActiveIndex(-1);
    };

    return (
        <div>
            <div className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex">
                        <div className="font-bold text-lg text-greyscale-900 mr-3">Mandatory Check Questions</div>
                        <div className="flex items-center justify-center rounded-20 bg-green-600 text-white text-xs px-3 py-1">
                            {questions?.length || 0} Question
                        </div>
                    </div>
                    <Button
                        onClick={() =>
                            setVisibleMenus((prevState: any) =>
                                prevState.includes(pageKey)
                                    ? prevState.filter((item: string) => item !== pageKey)
                                    : [...prevState, pageKey],
                            )
                        }
                        className={`w-6 h-6 p-0 ${visibleMenus.includes(pageKey) && 'bg-greyscale-100'}`}
                    >
                        {!visibleMenus.includes(pageKey) ? <MinusLogo /> : <PlusLogo />}
                    </Button>
                </div>
                {!visibleMenus.includes(pageKey) && (
                    <div>
                        {questions?.map((question, index) => (
                            <div key={index} className="flex flex-1 mb-6">
                                <div>
                                    <Button
                                        onMouseEnter={() => setActiveIndex(index)}
                                        onMouseLeave={() => setActiveIndex(-1)}
                                        onClick={() => handleDeleteQuestion(index)}
                                        className="min-h-full px-2.5 mr-6"
                                    >
                                        {index === activeIndex ? (
                                            <Delete3Logo />
                                        ) : (
                                            <div className="w-5 text-base text-greyscale-400">#{index + 1}</div>
                                        )}
                                    </Button>
                                </div>
                                <div className="flex flex-col flex-1">
                                    <div className="font-normal text-sm text-greyscale-900 mb-2">
                                        Question {question?.number}
                                    </div>
                                    <Input
                                        value={question?.question}
                                        onChange={(e) => handleQuestionChange(e, index)}
                                    />
                                </div>
                            </div>
                        ))}
                        <div className="flex flex-col items-end justify-end">
                            <Button icon={<AddCircleGreyLogo />} onClick={() => handleAddQuestion()}>
                                Add New
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Questions;
