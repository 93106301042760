import React, { useEffect, useState } from 'react';

// components
import { Button, Card, Dropdown, Table, Input, Modal, Typography } from 'antd';
import AddAccount from 'dialogs/add-account';
import { DotHorizontal, SearchLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import { jwtDecode } from 'jwt-decode';

// assets
import { DeleteLogo } from 'assets/svg';

const { Text } = Typography;
interface IAccount {
    id?: string;
    name: string;
    timezone: string;
    logo_url?: string;
}

interface DecodedJwt {
    exp: number;
    user_id: string;
}

export default function Accounts() {
    const [accounts, setAccounts] = useState<IAccount[]>([]);
    const [addAccountModalVisible, setAddAccountModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalCount, setTotalCount] = useState(0);

    const handleSaveAccountClick = async (data: any) => {
        setIsLoading(true);

        if (!selectedAccount?.id) {
            try {
                const payload = {
                    account: {
                        ...data,
                        logo_url: 'string',
                    },
                };

                const response = await axiosClient.post(apiURL.accounts, payload);

                setAccounts((prevState) => [response.data, ...prevState]);
                setAddAccountModalVisible(false);
                setIsLoading(false);
                // upload logo
                if (data?.logo_url) {
                    const formData = new FormData();
                    formData.append('logo', data?.logo_url, data?.logo_url?.name || 'logo.jpg');
                    await axiosClient.patch(`${apiURL.accounts}/${response.data.id}/image`, formData);
                    setAccounts((prevState) => {
                        const index = prevState.findIndex((account) => account.id === response.data.id);
                        prevState[index] = { ...prevState[index], logo_url: data?.logo_url };
                        return [...prevState];
                    });
                }
            } catch (error) {
                setIsLoading(false);
                notify({ type: 'error', message: error?.toString() || 'Error creating account' });
                console.error('error creating account', error);
            }
        } else {
            try {
                const payload = {
                    account: {
                        ...data,
                    },
                };
                await axiosClient.put(`${apiURL.accounts}/${selectedAccount?.id}`, payload);
                if (data?.logo_url) {
                    const formData = new FormData();
                    formData.append('logo', data?.logo_url, data?.logo_url?.name || 'logo.jpg');
                    await axiosClient.patch(`${apiURL.accounts}/${selectedAccount?.id}/image`, formData);
                }
                setAccounts((prevState) => {
                    const index = prevState.findIndex((account) => account.id === selectedAccount?.id);
                    prevState[index] = { ...prevState[index], ...data };
                    return [...prevState];
                });
                setAddAccountModalVisible(false);
            } catch (error) {
                notify({ type: 'error', message: error?.toString() || 'Error updating account' });
                console.error('error updating account', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const getAccounts = async () => {
        setIsLoading(true);
        try {
            const res = await axiosClient.get(`/accounts?page=${currentPage}`);
            setAccounts(res?.data?.data?.map((account: IAccount) => ({ ...account, key: account.id })));

            setTotalCount(res?.data?.meta.total);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error fetching accounts' });
            console.error('error fetching accounts', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditClick = (record: IAccount) => {
        setSelectedAccount(record);
        setAddAccountModalVisible(true);
    };

    const handleDeleteModalOpen = (record: IAccount) => {
        setDeleteModalVisible(true);
        setSelectedAccount(record);
    };

    const handleDeleteClick = async () => {
        setIsLoading(true);
        try {
            await axiosClient.delete(`${apiURL.accounts}/${selectedAccount?.id}`);
            setAccounts((prevState) => prevState.filter((account) => account.id !== selectedAccount?.id));
            setDeleteModalVisible(false);
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error deleting account' });
            console.error('error deleting account', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSwitchAccount = async (record: IAccount) => {
        setIsLoading(true);
        try {
            const accessToken = localStorage.getItem('accessToken') || null;
            if (accessToken) {
                const decoded: DecodedJwt = jwtDecode(accessToken);

                await axiosClient.put(`${apiURL.users}/${decoded.user_id}`, {
                    user: {
                        active_account_id: record.id,
                    },
                });
            }

            notify({ type: 'success', message: 'Account changed successfully' });
            window.location.reload();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            notify({ type: 'error', message: error?.toString() });
        }
    };

    useEffect(() => {
        getAccounts();
    }, [currentPage]);

    const columns = [
        {
            title: 'ACCOUNT NAME',
            dataIndex: 'name',
            key: 'name',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'TIMEZONE',
            dataIndex: 'timezone',
            key: 'timezone',
            render: (item: string) => <div>{item ? item : '-'}</div>,
        },
        {
            title: 'ACTIONS',
            dataIndex: 'actions',
            key: 'actions',
            render: (text: string, record: IAccount) => (
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: '1',
                                label: <div onClick={() => handleEditClick(record)}>Edit</div>,
                            },
                            {
                                key: '2',
                                label: <div onClick={() => handleDeleteModalOpen(record)}>Delete</div>,
                            },
                            {
                                key: '3',
                                label: <div onClick={() => handleSwitchAccount(record)}>Switch to Account</div>,
                            },
                        ],
                    }}
                    placement="bottom"
                >
                    <DotHorizontal />
                </Dropdown>
            ),
        },
    ];

    return (
        <Card className="flex-1 h-min" bordered={false}>
            <div>
                <div className="flex justify-between mb-5">
                    <Input className="max-w-lg" placeholder="Search Accounts" prefix={<SearchLogo className="w-4" />} />
                    <Button
                        onClick={() => {
                            setSelectedAccount(null);
                            setAddAccountModalVisible(true);
                        }}
                        type="primary"
                    >
                        Create Account
                    </Button>
                </div>
                <Table
                    className="flex flex-col flex-1 h-full"
                    loading={isLoading}
                    dataSource={accounts}
                    columns={columns}
                    onChange={(pagination) => {
                        setCurrentPage(pagination.current || 1);
                    }}
                    pagination={{ total: totalCount, showSizeChanger: false }}
                />
            </div>
            <AddAccount
                account={selectedAccount}
                visible={addAccountModalVisible}
                onSave={handleSaveAccountClick}
                onClose={() => setAddAccountModalVisible(false)}
            />
            <Modal
                title="Modal"
                open={deleteModalVisible}
                onCancel={() => setDeleteModalVisible(false)}
                footer={[
                    <Button className="flex-1" key="submit" danger onClick={handleDeleteClick} icon={<DeleteLogo />}>
                        Delete
                    </Button>,
                ]}
            >
                <Text>Are you sure you want to delete this account?</Text>
            </Modal>
        </Card>
    );
}
