import React, { useState, useRef, useEffect } from 'react';

// components
import { Button, Input, Modal, Select, Typography, Col, Row, Divider } from 'antd';
import Label from 'components/label';

// styles
import buckLogo from 'assets/buck-logo.png';

// helpers
import { timezones } from 'utils/timezones';
import notify from 'utils/notification';

// assets
import { SaveLogo } from 'assets/svg';

const { Text } = Typography;

interface IProps {
    account?: any;
    visible: boolean;
    onClose: () => void;
    onSave: (data: any) => void;
}

const App: React.FC<IProps> = ({ account, visible, onSave, onClose }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [name, setName] = useState('');
    const [timezone, setTimezone] = useState('');

    const handleImageChange = (e: any) => {
        const file = e.target.files[0];
        setSelectedImage(file);
        if (inputRef?.current) inputRef.current.value = '';
    };

    const handleTimeZoneChange = (value: string) => {
        setTimezone(value);
    };

    const handleSaveClick = () => {
        if (!name || !timezone) {
            notify({ type: 'error', message: 'Please fill in name and timezone fields' });
            return;
        }

        if (onSave) {
            onSave({ name, timezone, logo_url: selectedImage });
        }
    };

    useEffect(() => {
        if (account?.id && visible) {
            setName(account?.name);
            setTimezone(account?.timezone);
            setSelectedImage(account?.logo_url);
        }
    }, [account?.id, visible]);

    useEffect(() => {
        if (!visible) {
            setName('');
            setTimezone('');
            setSelectedImage(null);
        }
    }, [visible]);

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            footer={[
                <Button className="flex-1" key="submit" type="primary" onClick={handleSaveClick} icon={<SaveLogo />}>
                    Save
                </Button>,
            ]}
        >
            <div className="flex flex-col">
                <Text className="text-lg font-bold">Logo</Text>
                <Text className="text-greyscale-600 text-sm">
                    We only support JPG, JPEG or PNG file. Less than 2 megabyte.
                </Text>
            </div>
            <Row className="flex items-center mt-4">
                <div className="w-20 h-20 bg-greyscale-100 rounded-full flex items-center justify-center mr-4">
                    <input
                        ref={inputRef}
                        className="hidden"
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                    {selectedImage ? (
                        <img
                            className="w-full h-full rounded-full"
                            src={
                                (selectedImage as File | Blob) instanceof File ||
                                (selectedImage as File | Blob) instanceof Blob
                                    ? URL.createObjectURL(selectedImage as File | Blob)
                                    : selectedImage
                            }
                            alt="Selected"
                        />
                    ) : (
                        <img className="h-10" src={buckLogo} alt="Buck Logo" />
                    )}
                </div>
                <Button className="mr-2" onClick={() => inputRef.current?.click()} type="primary">
                    Upload your photo
                </Button>
                <Button
                    onClick={() => {
                        setSelectedImage(null);
                        if (inputRef.current) inputRef.current.value = '';
                    }}
                >
                    Delete Image
                </Button>
            </Row>
            <Divider className="my-6" />
            <Row>
                <div className="flex flex-col">
                    <Text className="text-lg font-bold">General Information</Text>
                    <Text className="text-greyscale-600 text-sm">You can add new account</Text>
                </div>
            </Row>
            <Row className="mt-4" gutter={16}>
                <Col span={24}>
                    <Label>Name</Label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full mb-4"
                        placeholder="Name"
                    />
                    <Label>Time Zone</Label>
                    <Select
                        value={timezone}
                        onChange={handleTimeZoneChange}
                        className="w-full mb-4"
                        placeholder="Time Zone"
                        showSearch
                        filterOption={(input, option) => (option?.label ?? '')?.includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={timezones.map((el) => ({ label: el.label, value: el.value }))}
                    />
                </Col>
            </Row>
        </Modal>
    );
};

export default App;
