import React, { useEffect, useState } from 'react';

// components
import { Button, Select, Modal, Col } from 'antd';
import notify from 'utils/notification';
// assets
import { SaveLogo, CloseIcon } from 'assets/svg';

// types
interface OptionProps {
    id: string;
    name: string;
}

interface ITag {
    id: string;
    name: string;
}

interface IActiveTag {
    serviceId: string;
    tags: ITag[];
}

interface IProps {
    activeTag: IActiveTag;
    options: OptionProps[];
    visible: boolean;
    onClose: () => void;
    onSave: (val: any) => void;
}

const AddTag: React.FC<IProps> = ({ options, activeTag, visible, onSave, onClose }) => {
    const [selectedItems, setSelectedItems] = useState<{ label: string; value: string }[]>([]);

    const handleSaveClick = async () => {
        if (!selectedItems?.length) {
            notify({ type: 'error', message: 'Please select at least one tag' });
            return;
        }
        if (onSave) {
            onSave(selectedItems);
            setSelectedItems([]);
        }
    };

    const handleRemoveTagClick = (id: string) => {
        setSelectedItems(selectedItems?.filter((item) => item?.value !== id));
    };

    const filteredOptions = options?.filter((option) => {
        const selecteds = selectedItems?.map((v) => v?.value) || [];
        return !selecteds?.includes(option?.id);
    });

    const handleClose = () => {
        if (onClose) {
            onClose();
            setSelectedItems([]);
        }
    };

    useEffect(() => {
        if (activeTag?.tags?.length) {
            setSelectedItems(
                activeTag?.tags.map((tag) => {
                    return {
                        label: tag?.name,
                        value: tag?.id,
                    };
                }),
            );
        }
    }, [activeTag]);

    return (
        <Modal
            forceRender
            width={450}
            title={'Tag Management'}
            open={visible}
            onCancel={handleClose}
            okText="Save"
            footer={[
                <Button
                    className="flex-1 w-full"
                    key="submit"
                    type="primary"
                    onClick={handleSaveClick}
                    icon={<SaveLogo />}
                >
                    Save
                </Button>,
            ]}
        >
            <Col span={24}>
                <Select
                    labelInValue={true}
                    mode="multiple"
                    className="w-full"
                    value={selectedItems}
                    placeholder="Select from list"
                    options={filteredOptions.map((item) => ({
                        value: item.id,
                        label: item.name,
                    }))}
                    onChange={setSelectedItems}
                />
                <div className="my-6 border-t-0 border-solid border-greyscale-250" />
                <div className="flex flex-wrap">
                    {selectedItems?.length ? (
                        selectedItems.map((item) => {
                            return (
                                <div
                                    className="flex items-center border border-solid border-greyscale-250 rounded-20 px-3 py-1 ml-3 mb-3 text-greyscale-900 text-xs"
                                    key={item.value}
                                >
                                    {item.label}
                                    <CloseIcon
                                        onClick={() => handleRemoveTagClick(item.value)}
                                        className="w-3 h-3 ml-2 cursor-pointer"
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <div className="text-greyscale-900 text-sm">Your added tags will show in this area</div>
                    )}
                </div>
            </Col>
        </Modal>
    );
};

export default AddTag;
