import React from 'react';
import { Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

// components
import { PhoneIntegrationLogo } from 'assets/svg';
import formatUSAPhone from 'utils/format-usa-phone';

interface IProps {
    visible: boolean;
    onClose: () => void;
    integrationPhone: string | undefined;
}

const IntegrationPhoneModal: React.FC<IProps> = ({ visible, onClose, integrationPhone }) => {
    return (
        <Modal
            centered
            className="w-[413px]"
            classNames={{ body: 'flex flex-1 flex-col p-6' }}
            closeIcon={<CloseCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="text-xl font-semibold text-grey-scale-900 mb-6">Phone Information</div>
            <div className="flex flex-1 flex-col">
                <div className="flex">
                    <div>
                        <PhoneIntegrationLogo />
                    </div>

                    <div className="ml-6">
                        <div className="text-sm text-grey-scale-900 font-semibold mb-2">Phone</div>
                        <div>{formatUSAPhone(integrationPhone as string)}</div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default IntegrationPhoneModal;
