import React, { useState } from 'react';

// components
import { Button, Col, Row, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// assets
import { AddCircleGreyLogo, MinusLogo, PlusLogo, Delete3Logo } from 'assets/svg';

interface ServicePoliciesProps {
    visibleMenus: string[];
    servicePolicies: any[];
    setPlaybook: (prevState: any) => void;
    setVisibleMenus: (prevState: any) => void;
}

const pageKey = 'service-policies';
const ServicePolicies: React.FC<ServicePoliciesProps> = ({
    servicePolicies,
    setPlaybook,
    visibleMenus,
    setVisibleMenus,
}) => {
    const [activeIndex, setActiveIndex] = useState<number>(-1);

    const handlePolicyChange = (e: any, index: number) => {
        const { name, value } = e.target;

        setPlaybook((prevState: any) => ({
            ...prevState,
            service_policies: prevState?.service_policies?.map((policy: any, i: number) =>
                i === index ? { ...policy, [name]: value } : policy,
            ),
        }));
    };

    const handleAddPolicy = () => {
        setPlaybook((prevState: any) => ({
            ...prevState,
            service_policies: [
                ...(prevState?.service_policies || []),
                {
                    name: '',
                    instructions: '',
                },
            ],
        }));
    };

    const handleDeletePolicy = (index: number) => {
        setPlaybook((prevState: any) => ({
            ...prevState,
            service_policies: prevState?.service_policies?.filter((item: any, i: number) => i !== index),
        }));
        setActiveIndex(-1);
    };

    return (
        <div>
            <div className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}>
                <div className="flex justify-between items-center mb-6">
                    <div className="flex">
                        <div className="font-bold text-lg text-greyscale-900 mr-3">Service Policy</div>
                        <div className="flex items-center justify-center rounded-20 bg-green-600 text-white text-xs px-3 py-1">
                            {servicePolicies?.length || 0} Policy
                        </div>
                    </div>
                    <Button
                        onClick={() =>
                            setVisibleMenus((prevState: any) =>
                                prevState.includes(pageKey)
                                    ? prevState.filter((item: string) => item !== pageKey)
                                    : [...prevState, pageKey],
                            )
                        }
                        className={`w-6 h-6 p-0 ${visibleMenus.includes(pageKey) && 'bg-greyscale-100'}`}
                    >
                        {!visibleMenus.includes(pageKey) ? <MinusLogo /> : <PlusLogo />}
                    </Button>
                </div>
                {!visibleMenus.includes(pageKey) && (
                    <div>
                        {servicePolicies?.map((policy, index) => (
                            <div key={index} className="flex flex-1 mb-6">
                                <div>
                                    <Button
                                        onMouseEnter={() => setActiveIndex(index)}
                                        onMouseLeave={() => setActiveIndex(-1)}
                                        onClick={() => handleDeletePolicy(index)}
                                        className="min-h-full px-2.5 mr-6"
                                    >
                                        {index === activeIndex ? (
                                            <Delete3Logo />
                                        ) : (
                                            <div className="w-5 text-base text-greyscale-400">#{index + 1}</div>
                                        )}
                                    </Button>
                                </div>
                                <div className="flex flex-1">
                                    <Row className="w-full" gutter={24}>
                                        <Col lg={12}>
                                            <Col className="mb-3" lg={24}>
                                                <div className="font-normal text-sm text-greyscale-900 mb-2">Name</div>
                                                <Input
                                                    name="name"
                                                    placeholder="Type here"
                                                    value={policy?.name}
                                                    onChange={(e) => handlePolicyChange(e, index)}
                                                />
                                            </Col>
                                        </Col>
                                        <Col lg={12}>
                                            <div className="font-normal text-sm text-greyscale-900 mb-2">
                                                Instructions
                                            </div>
                                            <TextArea
                                                className="!h-[calc(100%-28px)]"
                                                name="instructions"
                                                placeholder="Type here"
                                                value={policy?.instructions}
                                                onChange={(e) => handlePolicyChange(e, index)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        ))}
                        <div className="flex flex-col items-end justify-end">
                            <Button icon={<AddCircleGreyLogo />} onClick={() => handleAddPolicy()}>
                                Add New
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ServicePolicies;
