import React from 'react';
import { Button } from 'antd';
import { EmptyStateIcon } from 'assets/svg';

interface IProps {
    className?: string;
    emptyStateTitle: string;
    emptyStateInfo: string;
    secondaryButtonText?: string;
    secondaryButtonAction?: () => void;
    primaryButtonText: string;
    primaryButtonAction: () => void;
}

const EmptyStateCard: React.FC<IProps> = ({
    emptyStateTitle,
    className,
    emptyStateInfo,
    secondaryButtonText,
    primaryButtonText,
    secondaryButtonAction,
    primaryButtonAction,
}) => {
    return (
        <div
            className={`border rounded-10 border-solid border-greyscale-200 bg-white p-6 flex flex-col items-center ${className}`}
        >
            <EmptyStateIcon />
            <div className="text-greyscale-900 text-lg font-bold mt-2">{emptyStateTitle}</div>
            <div className="text-greyscale-900 text-xs mt-3">{emptyStateInfo}</div>
            <div className="flex justify-center items-center mt-6">
                {secondaryButtonText && (
                    <Button className="mr-6" onClick={secondaryButtonAction}>
                        {secondaryButtonText}
                    </Button>
                )}
                <Button type="primary" onClick={primaryButtonAction}>
                    {primaryButtonText}
                </Button>
            </div>
        </div>
    );
};

export default EmptyStateCard;
