import React from 'react';

import { Select } from 'antd';

interface IPickerProps {
    onChange?: (time: any) => void;
    value?: any;
}

const CustomTimePicker: React.FC<IPickerProps> = ({ onChange, value }) => {
    const times = [];
    const startTime = 0;
    const endTime = 24;

    for (let hour = startTime; hour < endTime; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
            const time = new Date().setHours(hour, minute);
            times.push(time);
        }
    }

    const formatTime = (time: any) => {
        const date = new Date(time);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    };

    return (
        <Select
            labelInValue
            showSearch
            value={value}
            placeholder="Select Time"
            onSearch={(val) => console.log(val)}
            optionFilterProp="label"
            onChange={onChange}
            options={times.map((time) => ({
                label: formatTime(time),
                value: time,
            }))}
        />
    );
};

export default CustomTimePicker;
