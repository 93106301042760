import { SET_BREADCRUMB } from 'context/breadcrump/keys';

export const initialState = {
    breadcrumbs: [{ title: '', path: '/' }],
};

const reducer = (state = initialState, { type, payload }: { type: string; payload: any }) => {
    switch (type) {
        case SET_BREADCRUMB: {
            return {
                ...state,
                breadcrumbs: payload,
            };
        }
        default:
            return state;
    }
};

export default reducer;
