import React from 'react';
import { useSearchParams } from 'react-router-dom';

// components
import { Col, Form, Input, Row, Typography } from 'antd';
import WelcomeBanner from 'components/welcome-banner';
import Spinner from 'components/spinner';
import { useNavigate } from 'react-router-dom';

// styles
import { BuckLogo } from 'assets/svg';

// helpers
import notify from 'utils/notification';
import { apiURL, axiosClient } from 'service';

const { Title, Text } = Typography;

// types
interface ResetPasswordFormValues {
    password: string;
    password_confirmation: string;
}

const ResetPassword: React.FC = () => {
    const navigate = useNavigate();

    const [form] = Form.useForm();
    const [searchParams] = useSearchParams();

    const passwordToken = searchParams.get('token');

    const [isLoading, setIsLoading] = React.useState(false);

    const onSubmit = async (values: ResetPasswordFormValues) => {
        if (values.password !== values.password_confirmation) {
            notify({ type: 'error', message: 'Passwords do not match' });
            return;
        }
        setIsLoading(true);
        axiosClient
            .put(apiURL.passwordReset, {
                user: {
                    token: passwordToken,
                    ...values,
                },
            })
            .then(() => {
                notify({ type: 'success', message: 'Password was reset successfully' });
            })
            .catch((error) => {
                notify({ type: 'error', message: error?.toString() });
            })
            .finally(() => {
                setIsLoading(false);
                navigate('/login');
            });
    };

    return (
        <div className="flex h-screen">
            <Row className="flex-1">
                <Col className="flex flex-col flex-1 xl:items-start justify-between p-4 xl:p-12" xl={12}>
                    <BuckLogo className="h-12 w-min" />
                    <section className="flex flex-col mx-auto max-w-lg w-full text-center gap-6">
                        <div className="flex flex-col gap-2.5">
                            <Title level={2}>
                                <span className="text-primary">Reset</span> Password
                            </Title>
                            <Text className="text-greyscale-600">
                                Create a new password to continue. Make sure it is at least 8 characters including a
                                number and a lowercase letter.
                            </Text>
                        </div>
                        <Form form={form} initialValues={{ remember: true }} onFinish={onSubmit}>
                            <Form.Item
                                className="mb-2"
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<span className="icon-lock"></span>}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>
                            <Form.Item
                                className="mb-6"
                                name="password_confirmation"
                                rules={[{ required: true, message: 'Please confirm your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<span className="icon-lock"></span>}
                                    type="password"
                                    placeholder="Confirm Password"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Input type="submit" value="Continue" />
                            </Form.Item>
                        </Form>
                    </section>
                    <footer className="flex justify-between w-full">
                        <a
                            href="https://buck.ai/terms"
                            className="text-primary hover:text-primary-500 hover:underline font-semibold"
                        >
                            Privacy Policy
                        </a>
                        <span className="text-greyscale-600 font-semibold text-sm">Copyright © 2022</span>
                    </footer>
                </Col>
                <WelcomeBanner />
            </Row>
            <Spinner visible={isLoading} />
        </div>
    );
};

export default ResetPassword;
