import { createElement, ReactNode } from 'react';
import { FontSizes, FontStyles, FontWeights, TextAligns, TextColors } from '../Types';

interface TextProps {
    tagName: 'p' | 'small' | 'span' | 'sub' | 'sup';
    children: ReactNode;
    fontStyle?: FontStyles;
    fontWeight?: FontWeights;
    fontSize?: FontSizes;
    fontColor?: TextColors;
    textAlign?: TextAligns;
    className?: Array<string>;
}

export default function Text({
    tagName,
    children,
    className = [],
    fontStyle,
    fontWeight,
    fontColor,
    fontSize,
    textAlign,
}: TextProps) {
    const elementClasses = new Set([fontStyle, fontWeight, fontColor, fontSize, textAlign, ...className]);

    return createElement(tagName, { className: Array.from(elementClasses).join(' ') }, children);
}
