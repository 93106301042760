export const timezones = [
    { label: 'International Date Line West', value: 'Etc/GMT+12' },
    { label: 'Midway Island', value: 'Pacific/Midway' },
    { label: 'American Samoa', value: 'Pacific/Pago_Pago' },
    { label: 'Hawaii', value: 'Pacific/Honolulu' },
    { label: 'Alaska', value: 'America/Juneau' },
    { label: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
    { label: 'Tijuana', value: 'America/Tijuana' },
    { label: 'Mountain Time (US & Canada)', value: 'America/Denver' },
    { label: 'Arizona', value: 'America/Phoenix' },
    { label: 'Chihuahua', value: 'America/Chihuahua' },
    { label: 'Mazatlan', value: 'America/Mazatlan' },
    { label: 'Central Time (US & Canada)', value: 'America/Chicago' },
    { label: 'Saskatchewan', value: 'America/Regina' },
    { label: 'Guadalajara', value: 'America/Mexico_City' },
    { label: 'Mexico City', value: 'America/Mexico_City' },
    { label: 'Monterrey', value: 'America/Monterrey' },
    { label: 'Central America', value: 'America/Guatemala' },
    { label: 'Eastern Time (US & Canada)', value: 'America/New_York' },
    { label: 'Indiana (East)', value: 'America/Indiana/Indianapolis' },
    { label: 'Bogota', value: 'America/Bogota' },
    { label: 'Lima', value: 'America/Lima' },
    { label: 'Quito', value: 'America/Lima' },
    { label: 'Atlantic Time (Canada)', value: 'America/Halifax' },
    { label: 'Caracas', value: 'America/Caracas' },
    { label: 'La Paz', value: 'America/La_Paz' },
    { label: 'Santiago', value: 'America/Santiago' },
    { label: 'Newfoundland', value: 'America/St_Johns' },
    { label: 'Brasilia', value: 'America/Sao_Paulo' },
    { label: 'Buenos Aires', value: 'America/Argentina/Buenos_Aires' },
    { label: 'Montevideo', value: 'America/Montevideo' },
    { label: 'Georgetown', value: 'America/Guyana' },
    { label: 'Puerto Rico', value: 'America/Puerto_Rico' },
    { label: 'Greenland', value: 'America/Godthab' },
    { label: 'Mid-Atlantic', value: 'Atlantic/South_Georgia' },
    { label: 'Azores', value: 'Atlantic/Azores' },
    { label: 'Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
    { label: 'Dublin', value: 'Europe/Dublin' },
    { label: 'Edinburgh', value: 'Europe/London' },
    { label: 'Lisbon', value: 'Europe/Lisbon' },
    { label: 'London', value: 'Europe/London' },
    { label: 'Casablanca', value: 'Africa/Casablanca' },
    { label: 'Monrovia', value: 'Africa/Monrovia' },
    { label: 'UTC', value: 'Etc/UTC' },
    { label: 'Belgrade', value: 'Europe/Belgrade' },
    { label: 'Bratislava', value: 'Europe/Bratislava' },
    { label: 'Budapest', value: 'Europe/Budapest' },
    { label: 'Ljubljana', value: 'Europe/Ljubljana' },
    { label: 'Prague', value: 'Europe/Prague' },
    { label: 'Sarajevo', value: 'Europe/Sarajevo' },
    { label: 'Skopje', value: 'Europe/Skopje' },
    { label: 'Warsaw', value: 'Europe/Warsaw' },
    { label: 'Zagreb', value: 'Europe/Zagreb' },
    { label: 'Brussels', value: 'Europe/Brussels' },
    { label: 'Copenhagen', value: 'Europe/Copenhagen' },
    { label: 'Madrid', value: 'Europe/Madrid' },
    { label: 'Paris', value: 'Europe/Paris' },
    { label: 'Amsterdam', value: 'Europe/Amsterdam' },
    { label: 'Berlin', value: 'Europe/Berlin' },
    { label: 'Bern', value: 'Europe/Zurich' },
    { label: 'Zurich', value: 'Europe/Zurich' },
    { label: 'Rome', value: 'Europe/Rome' },
    { label: 'Stockholm', value: 'Europe/Stockholm' },
    { label: 'Vienna', value: 'Europe/Vienna' },
    { label: 'West Central Africa', value: 'Africa/Algiers' },
    { label: 'Bucharest', value: 'Europe/Bucharest' },
    { label: 'Cairo', value: 'Africa/Cairo' },
    { label: 'Helsinki', value: 'Europe/Helsinki' },
    { label: 'Kyiv', value: 'Europe/Kiev' },
    { label: 'Riga', value: 'Europe/Riga' },
    { label: 'Sofia', value: 'Europe/Sofia' },
    { label: 'Tallinn', value: 'Europe/Tallinn' },
    { label: 'Vilnius', value: 'Europe/Vilnius' },
    { label: 'Athens', value: 'Europe/Athens' },
    { label: 'Istanbul', value: 'Europe/Istanbul' },
    { label: 'Minsk', value: 'Europe/Minsk' },
    { label: 'Jerusalem', value: 'Asia/Jerusalem' },
    { label: 'Harare', value: 'Africa/Harare' },
    { label: 'Pretoria', value: 'Africa/Johannesburg' },
    { label: 'Kaliningrad', value: 'Europe/Kaliningrad' },
    { label: 'Moscow', value: 'Europe/Moscow' },
    { label: 'St. Petersburg', value: 'Europe/Moscow' },
    { label: 'Volgograd', value: 'Europe/Volgograd' },
    { label: 'Samara', value: 'Europe/Samara' },
    { label: 'Kuwait', value: 'Asia/Kuwait' },
    { label: 'Riyadh', value: 'Asia/Riyadh' },
    { label: 'Nairobi', value: 'Africa/Nairobi' },
    { label: 'Baghdad', value: 'Asia/Baghdad' },
    { label: 'Tehran', value: 'Asia/Tehran' },
    { label: 'Abu Dhabi', value: 'Asia/Muscat' },
    { label: 'Muscat', value: 'Asia/Muscat' },
    { label: 'Baku', value: 'Asia/Baku' },
    { label: 'Tbilisi', value: 'Asia/Tbilisi' },
    { label: 'Yerevan', value: 'Asia/Yerevan' },
    { label: 'Kabul', value: 'Asia/Kabul' },
    { label: 'Ekaterinburg', value: 'Asia/Yekaterinburg' },
    { label: 'Islamabad', value: 'Asia/Karachi' },
    { label: 'Karachi', value: 'Asia/Karachi' },
    { label: 'Tashkent', value: 'Asia/Tashkent' },
    { label: 'Chennai', value: 'Asia/Kolkata' },
    { label: 'Kolkata', value: 'Asia/Kolkata' },
    { label: 'Mumbai', value: 'Asia/Kolkata' },
    { label: 'New Delhi', value: 'Asia/Kolkata' },
    { label: 'Kathmandu', value: 'Asia/Kathmandu' },
    { label: 'Astana', value: 'Asia/Dhaka' },
    { label: 'Dhaka', value: 'Asia/Dhaka' },
    { label: 'Sri Jayawardenepura', value: 'Asia/Colombo' },
    { label: 'Almaty', value: 'Asia/Almaty' },
    { label: 'Novosibirsk', value: 'Asia/Novosibirsk' },
    { label: 'Rangoon', value: 'Asia/Rangoon' },
    { label: 'Bangkok', value: 'Asia/Bangkok' },
    { label: 'Hanoi', value: 'Asia/Bangkok' },
    { label: 'Jakarta', value: 'Asia/Jakarta' },
    { label: 'Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
    { label: 'Beijing', value: 'Asia/Shanghai' },
    { label: 'Chongqing', value: 'Asia/Chongqing' },
    { label: 'Hong Kong', value: 'Asia/Hong_Kong' },
    { label: 'Urumqi', value: 'Asia/Urumqi' },
    { label: 'Kuala Lumpur', value: 'Asia/Kuala_Lumpur' },
    { label: 'Singapore', value: 'Asia/Singapore' },
    { label: 'Taipei', value: 'Asia/Taipei' },
    { label: 'Perth', value: 'Australia/Perth' },
    { label: 'Irkutsk', value: 'Asia/Irkutsk' },
    { label: 'Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
    { label: 'Seoul', value: 'Asia/Seoul' },
    { label: 'Osaka', value: 'Asia/Tokyo' },
    { label: 'Sapporo', value: 'Asia/Tokyo' },
    { label: 'Tokyo', value: 'Asia/Tokyo' },
    { label: 'Yakutsk', value: 'Asia/Yakutsk' },
    { label: 'Darwin', value: 'Australia/Darwin' },
    { label: 'Adelaide', value: 'Australia/Adelaide' },
    { label: 'Canberra', value: 'Australia/Canberra' },
    { label: 'Melbourne', value: 'Australia/Melbourne' },
    { label: 'Sydney', value: 'Australia/Sydney' },
    { label: 'Brisbane', value: 'Australia/Brisbane' },
    { label: 'Hobart', value: 'Australia/Hobart' },
    { label: 'Vladivostok', value: 'Asia/Vladivostok' },
    { label: 'Guam', value: 'Pacific/Guam' },
    { label: 'Port Moresby', value: 'Pacific/Port_Moresby' },
    { label: 'Magadan', value: 'Asia/Magadan' },
    { label: 'Srednekolymsk', value: 'Asia/Srednekolymsk' },
    { label: 'Solomon Is.', value: 'Pacific/Guadalcanal' },
    { label: 'New Caledonia', value: 'Pacific/Noumea' },
    { label: 'Fiji', value: 'Pacific/Fiji' },
    { label: 'Kamchatka', value: 'Asia/Kamchatka' },
    { label: 'Marshall Is.', value: 'Pacific/Majuro' },
    { label: 'Auckland', value: 'Pacific/Auckland' },
    { label: 'Wellington', value: 'Pacific/Auckland' },
    { label: 'Nuku alofa', value: 'Pacific/Tongatapu' },
    { label: 'Tokelau Is.', value: 'Pacific/Fakaofo' },
    { label: 'Chatham Is.', value: 'Pacific/Chatham' },
    { label: 'Samoa', value: 'Pacific/Apia' },
];
