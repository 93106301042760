import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { IntegrationDefaultLogo } from 'assets/svg';
import { IntegrationStatus, ModalTypes } from 'utils/constants';
import { apiURL, axiosClient } from 'service';
import notify from 'utils/notification';

interface IProps {
    visible: boolean;
    onClose: () => void;
    activeAuthToken: string | undefined | null;
    integrationId: string;
    modalType: string;
    webChatOptions: any;
    account_logo_url: string;
    getNewList: () => void;
}

const IntegrationWebChatModal: React.FC<IProps> = ({
    visible,
    onClose,
    activeAuthToken,
    integrationId,
    account_logo_url,
    modalType,
    webChatOptions,
    getNewList,
}) => {
    const [webSite, setWebSite] = useState('');
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [script, setScript] = useState('');

    const handleSaveClick = async () => {
        setIsSaveLoading(true);
        const payload = {
            options: {
                websites: [webSite],
            },
            source: 'webchat',
            status: IntegrationStatus.active,
        };

        try {
            let res;
            if (modalType === ModalTypes.edit) {
                res = await axiosClient.put(`${apiURL.integrations}/${integrationId}`, payload);
            } else {
                res = await axiosClient.post(apiURL.integrations, payload);
            }

            const authToken = res.data.token;
            const accountLogoUrl = res.data.account_logo_url;

            setScript(`
                <script>
                    const script = document.createElement("script");
                    script.src = "https://cdn.buckplatform.com/webchat.js";
                    script.onload = function () {
                        const token = '${authToken}';
                        const accountLogoUrl = '${accountLogoUrl}';
                        window.initChatApp(token, accountLogoUrl);
                    };
                    document.head.appendChild(script);
                </script>
            `);
            getNewList();
        } catch (error) {
            notify({ type: 'error', message: error?.toString() || 'Error getting authentication token' });
        } finally {
            setIsSaveLoading(false);
        }
    };

    useEffect(() => {
        if (visible && activeAuthToken) {
            setScript(`
                <script>
                    const script = document.createElement("script");
                    script.src = "https://cdn.buckplatform.com/webchat.js";
                    script.onload = function () {
                        const token = '${activeAuthToken}';
                        const accountLogoUrl = '${account_logo_url}';
                        window.initChatApp(token, accountLogoUrl);
                    };
                    document.head.appendChild(script);
                </script>
            `);
            setWebSite(webChatOptions?.websites[0]);
        }
    }, [visible]);

    return (
        <Modal
            centered
            className="w-[480px]"
            classNames={{ body: 'flex flex-1 flex-col p-6' }}
            closeIcon={<CloseCircleOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />}
            open={visible}
            onCancel={onClose}
            footer={null}
        >
            <div className="text-xl font-semibold text-grey-scale-900 mb-6">Web Chat Integration</div>
            <div className="flex flex-1 flex-col">
                <div className="flex">
                    <div>
                        <IntegrationDefaultLogo />
                    </div>

                    <div className="ml-6 text-sm text-greyscale-500 mb-4 ">
                        To integrate web chat into your website, you need to add this script which you are going to take
                        as output to your app.
                    </div>
                </div>
                <div className="mt-6 mb-6">
                    <div className="text-sm text-grey-scale-900 font-semibold mb-2">Web Site</div>

                    <Input value={webSite} onChange={(e) => setWebSite(e.target.value)} placeholder="Web site" />
                </div>
                {script && <code className="bg-slate-900 rounded-lg px-4 py-2 text-white">{script}</code>}
                <div className="mt-6">
                    <Button
                        className="flex items-center justify-center flex-1 w-full"
                        type="primary"
                        onClick={handleSaveClick}
                        loading={isSaveLoading}
                    >
                        Save Changes
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default IntegrationWebChatModal;
