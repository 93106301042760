import React from 'react';

interface IProps {
    children: React.ReactNode | any;
    className?: string;
}

const Label: React.FC<IProps> = ({ children, className = '' }) => {
    return <label className={`text-sm font-semibold ${className}`}>{children}</label>;
};

export default Label;
