import React from 'react';

// components
import { Input } from 'antd';

interface MessagesProps {
    messages: any[];
    setPlaybook: (prevState: any) => void;
}

const Messages: React.FC<MessagesProps> = ({ messages = [], setPlaybook }) => {
    const handleMessageChange = (e: any) => {
        const { value } = e.target;
        setPlaybook((prevState: any) => {
            return {
                ...prevState,
                messages: [
                    {
                        key: 'greeting',
                        message: value,
                    },
                ],
            };
        });
    };
    return (
        <div>
            <div className={'border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 mb-6'}>
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <div className="text-sm text-greyscale-900 font-normal">Welcome Message</div>
                    </div>
                </div>
                <div>
                    <Input className="max-w-sm" value={messages[0]?.message} onChange={handleMessageChange} />
                </div>
            </div>
        </div>
    );
};

export default Messages;
