import React, { useState } from 'react';

// components
import { Select, Tag, Row, Col } from 'antd';
import Icon from '@ant-design/icons';
import { RightArrowLogo } from 'assets/svg';
import Spinner from 'components/spinner';

// helpers
import {
    PriorityLabel,
    ReasonLabel,
    Status,
    PriorityLabelWithColorAndIcon,
    ConversationTypeLabels,
} from 'utils/constants';
import { axiosClient, apiURL } from 'service';
import notify from 'utils/notification';
import { IConversation } from 'pages/conversations/conversations';
import { convertTimeToAccountTimezone } from 'utils/formatter';

interface ILabel {
    [key: string]: string;
}

interface IProps {
    conversation: IConversation;
    selectedConversationID: string | undefined;
    onClick: (id: string) => void;
}

const ConversationCard: React.FC<IProps> = ({ conversation, selectedConversationID, onClick }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleStatusChange = (value: string) => {
        if (value) {
            setIsLoading(true);
            axiosClient
                .put(`${apiURL.conversations}/${conversation.id}`, {
                    conversation: {
                        status: value,
                        reason: conversation?.reason,
                    },
                })
                .then((res) => {
                    // update status in conversation list
                    console.log('res', res?.data);
                    notify({ type: 'success', message: 'Status updated successfully' });
                })
                .catch((error) => {
                    notify({ type: 'error', message: error?.toString() });
                    console.error(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    };

    const disabledStatus = ['closed', 'error', 'inactive', 'in_conversation'];
    return (
        <div
            onClick={() => onClick(conversation?.id)}
            className={`flex items-center p-6 border-solid border-t-0 border-x-0 border-b-greyscale-100 hover:bg-amber-100 cursor-pointer ${
                conversation?.id === selectedConversationID
                    ? 'bg-amber-100 border-solid border-y-0 border-r-0 border-l-4 border-l-primary'
                    : ''
            }`}
        >
            <div className="flex-1">
                <div className="flex justify-between bg-greyscale-50 px-3 py-1 rounded-lg">
                    <div className="flex items-center text-xs font-medium">
                        {conversation?.created_at ? convertTimeToAccountTimezone(conversation.created_at) : '-'}
                    </div>
                    <div className="flex items-center">
                        <Select
                            variant="borderless"
                            className="w-40"
                            placeholder="Select Status"
                            onClick={(e) => e.stopPropagation()}
                            onChange={handleStatusChange}
                            value={conversation?.status}
                            disabled={disabledStatus?.includes(conversation?.status)}
                            options={statusOptions.map((option) => {
                                if (option?.value === Status.COMPLETED) {
                                    return {
                                        label: (
                                            <Tag className="font-semibold" color="success">
                                                {option.label}
                                            </Tag>
                                        ),
                                        value: option.value,
                                    };
                                }
                                if (option?.value === Status.REJECTED) {
                                    return {
                                        label: (
                                            <Tag className="font-semibold" color="error">
                                                {option.label}
                                            </Tag>
                                        ),
                                        value: option.value,
                                    };
                                }

                                if (option?.value === Status.AWAITING_AGENT) {
                                    return {
                                        label: (
                                            <Tag className="font-semibold" color="warning">
                                                {option.label}
                                            </Tag>
                                        ),
                                        value: option.value,
                                    };
                                }

                                return { label: option.label, value: option.value, disabled: true };
                            })}
                        />
                    </div>
                </div>
                <div className="mt-3">
                    <Row>
                        <Col span={8}>
                            <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">CHANNEL</div>
                            <div className="text-xs font-medium text-greyscale-900">
                                {ConversationTypeLabels[conversation?.source]
                                    ? ConversationTypeLabels[conversation?.source]
                                    : conversation?.source
                                      ? conversation?.source
                                      : '-'}
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">TYPE</div>
                            <div className="text-xs font-medium text-greyscale-900">
                                {(ReasonLabel as ILabel)[conversation?.reason]}
                            </div>
                        </Col>
                        <Col span={8}>
                            <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">PRIORITY</div>
                            <div
                                className={`${
                                    PriorityLabelWithColorAndIcon?.[conversation?.priority || 'normal']?.textClass
                                } text-xs font-medium mr-3`}
                            >
                                <Icon
                                    className="text-transparent"
                                    component={
                                        PriorityLabelWithColorAndIcon?.[conversation?.priority || 'normal']?.icon
                                    }
                                    onPointerEnterCapture={undefined}
                                    onPointerLeaveCapture={undefined}
                                />
                                <span className="ml-1">
                                    {(PriorityLabel as ILabel)[conversation?.priority || 'normal']}
                                </span>
                            </div>
                        </Col>
                    </Row>
                    {conversation?.reason?.includes(ReasonLabel.booking) ? (
                        <Row className="mt-4">
                            <Col span={8}>
                                {/*  job_types field is missing in api/conversations endpoint */}
                                <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">JOB TYPE</div>
                                <div className="text-xs font-medium text-greyscale-900">
                                    {conversation?.job_type || '-'}
                                </div>
                            </Col>
                            <Col span={8}>
                                {/* location field is missing in api/conversations endpoint  */}
                                <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">LOCATION</div>
                                <div className="text-xs font-medium text-greyscale-900">
                                    {conversation?.booking?.location_full_address || '-'}
                                </div>
                            </Col>
                            <Col span={8}>
                                {/* PREFERRED TIME  is missing in api/conversations endpoint  */}
                                <div className="text-[0.625rem] font-semibold text-greyscale-500 mb-1.5">
                                    CONFIRMED TIME
                                </div>
                                <div className="text-xs font-medium text-greyscale-900">
                                    {conversation?.confirmed_time
                                        ? convertTimeToAccountTimezone(conversation?.confirmed_time)
                                        : '-'}
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                </div>
            </div>
            {conversation?.id === selectedConversationID && (
                <div>
                    <RightArrowLogo />
                </div>
            )}
            <Spinner visible={isLoading} />
        </div>
    );
};

export default ConversationCard;

const statusOptions = [
    {
        label: 'Awaiting Agent',
        value: 'awaiting_agent',
    },
    {
        label: 'Completed',
        value: 'completed',
    },
    {
        label: 'Rejected',
        value: 'rejected',
    },
    {
        label: 'Closed',
        value: 'closed',
    },
    {
        label: 'Error',
        value: 'error',
    },
    {
        label: 'Inactive',
        value: 'inactive',
    },
    {
        label: 'In Conversation',
        value: 'in_conversation',
    },
];
