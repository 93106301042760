import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// components
import { Button, Row, Col, Checkbox, Tree, notification } from 'antd';
import Breadcrumb from 'components/breadcrumb';
import Spinner from 'components/spinner';
import { SaveLogo, EqualLogo } from 'assets/svg';

// assets
import { CircleDownLogo, CircleUpLogo } from 'assets/svg';

// helpers
import { axiosClient, apiURL } from 'service';

// context
import { AuthContext } from 'context';

const Services = () => {
    const navigate = useNavigate();
    const {
        state: { user },
    } = useContext(AuthContext);

    const [data, setData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const processData = (data: any) => {
        const result: any = {};

        data.forEach((item: any) => {
            const { trade, service_type, equipment_type, active, fsm_options, id } = item;

            if (!(trade.name in result)) {
                result[trade.name] = {
                    checkable: false,
                    title: trade.name,
                    id: trade.id,
                    active: false,
                    activeCount: 0,
                    totalCount: 0,
                    children: {},
                };
            }

            const tradeGroup = result[trade.name];

            if (!(equipment_type.id in tradeGroup.children)) {
                tradeGroup.children[equipment_type.id] = {
                    checkable: false,
                    id: equipment_type.id,
                    title: equipment_type.name,
                    activeCount: 0,
                    totalCount: 0,
                    active: false,
                    children: [],
                };
            }

            const equipmentGroup = tradeGroup.children[equipment_type.id];

            if (active) {
                tradeGroup.active = true;
                tradeGroup.activeCount += 1;
                equipmentGroup.activeCount += 1;
                equipmentGroup.active = true;
            }

            equipmentGroup.children.push({
                id,
                key: id,
                title: service_type.name,
                active,
                checkable: true,
                fsm_options: fsm_options,
            });

            tradeGroup.totalCount += 1;
            equipmentGroup.totalCount += 1;
        });

        return Object.values(result).map((trade: any) => ({
            ...trade,
            children: Object.values(trade.children),
        }));
    };

    const handleTradeChange = (tradeId: string) => {
        const updatedData = data.map((trade: any) => {
            if (trade.id === tradeId) {
                const newActiveState = !trade.active;

                const updatedEquipmentTypes = trade.children.map((equipment: any) => {
                    const updatedServiceTypes = equipment.children.map((service: any) => ({
                        ...service,
                        active: newActiveState,
                    }));

                    const activeCount = updatedServiceTypes.filter((service: any) => service.active).length;

                    return {
                        ...equipment,
                        active: activeCount > 0,
                        children: updatedServiceTypes,
                        activeCount: activeCount,
                    };
                });

                const activeCount = updatedEquipmentTypes.reduce((count: any, equipment: any) => {
                    return count + equipment.activeCount;
                }, 0);

                return {
                    ...trade,
                    active: newActiveState,
                    children: updatedEquipmentTypes,
                    activeCount: activeCount,
                };
            }

            return trade;
        });

        setData(updatedData);
    };

    const handleEquipmentTypeChange = (equipmentTypeId: string, tradeId: string) => {
        const updatedData = data.map((trade: any) => {
            if (trade.id === tradeId) {
                const updatedEquipmentTypes = trade.children.map((equipment: any) => {
                    if (equipment.id === equipmentTypeId) {
                        const newActiveState = !equipment.active;

                        const updatedServiceTypes = equipment.children.map((service: any) => ({
                            ...service,
                            active: newActiveState,
                        }));

                        const activeCount = updatedServiceTypes.filter((service: any) => service.active).length;

                        return {
                            ...equipment,
                            active: newActiveState,
                            children: updatedServiceTypes,
                            activeCount: activeCount,
                        };
                    }

                    return equipment;
                });

                const activeCount = updatedEquipmentTypes.reduce((count: any, equipment: any) => {
                    return count + equipment.activeCount;
                }, 0);

                return {
                    ...trade,
                    children: updatedEquipmentTypes,
                    activeCount: activeCount,
                };
            }

            return trade;
        });

        setData(updatedData);
    };

    const handleServiceTypeChange = (checkedKeys: any, e: any) => {
        const checkedServiceTypeKey = e.node.key;
        const isChecked = checkedKeys.includes(checkedServiceTypeKey);

        const updatedData = data.map((trade: any) => {
            // update trade
            const updatedEquipmentTypes = trade.children.map((equipment: any) => {
                // update equipmentType
                const updatedServiceTypes = equipment.children.map((service: any) => {
                    // update serviceType
                    if (service.key === checkedServiceTypeKey) {
                        return {
                            ...service,
                            active: isChecked,
                        };
                    }
                    return service;
                });

                // EquipmentType's activeCount
                const activeCount = updatedServiceTypes.filter((service: any) => service.active).length;
                return {
                    ...equipment,
                    children: updatedServiceTypes,
                    active: activeCount > 0,
                    activeCount,
                };
            });

            // Trade's activeCount
            const tradeActiveCount = updatedEquipmentTypes.reduce((total: number, equipment: any) => {
                return total + equipment.activeCount;
            }, 0);

            return {
                ...trade,
                children: updatedEquipmentTypes,
                activeCount: tradeActiveCount,
                active: tradeActiveCount > 0,
            };
        });

        setData(updatedData);
    };

    const handleAllTradesChange = () => {
        // check if there is any active trade
        const isThereActiveTrade = data.some((trade: any) => trade.active);

        const updatedData = data.map((trade: any) => {
            // update trade
            const newActiveState = !isThereActiveTrade;

            // update equipmentTypes and serviceTypes
            const updatedEquipmentTypes = trade.children.map((equipment: any) => {
                const updatedServiceTypes = equipment.children.map((service: any) => ({
                    ...service,
                    active: newActiveState,
                }));

                const activeCount = updatedServiceTypes.filter((service: any) => service.active).length;

                return {
                    ...equipment,
                    children: updatedServiceTypes,
                    active: activeCount > 0,
                    activeCount,
                };
            });

            const tradeActiveCount = updatedEquipmentTypes.reduce((total: number, equipment: any) => {
                return total + equipment.activeCount;
            }, 0);

            return {
                ...trade,
                active: newActiveState,
                children: updatedEquipmentTypes,
                activeCount: tradeActiveCount,
            };
        });

        setData(updatedData);
    };

    const getActiveServiceTypeKeys = () => {
        const activeServiceTypeKeys = data.flatMap((trade: any) =>
            trade.children.flatMap((equipment: any) =>
                equipment.children.filter((service: any) => service.active).map((service: any) => service.key),
            ),
        );

        return activeServiceTypeKeys;
    };

    const handleSaveClick = async () => {
        const services: any = {};

        data.forEach((trade: any) => {
            trade.children.forEach((equipment: any) => {
                equipment.children.forEach((serviceType: any) => {
                    services[serviceType.id] = {
                        active: serviceType.active,
                        fsm_options: serviceType.fsm_options,
                    };
                });
            });
        });

        const payload = {
            services: Object.keys(services).map((key) => {
                return {
                    id: key,
                    attributes: { active: services[key].active, fsm_options: services[key].fsm_options },
                };
            }),
        };

        try {
            await axiosClient.put(apiURL.services + '/batch', payload);
            getServices();
            notification.success({
                message: 'Success',
                description: 'Services updated successfully',
            });
        } catch (error) {
            console.error('error update services', error);
            notification.error({
                message: 'Error',
                description: 'Error update services',
            });
        }
    };

    const getServices = async () => {
        try {
            setIsLoading(true);
            const response = await axiosClient.get(apiURL.services);
            const data = response?.data;

            setData(processData(data));
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getServices();
    }, []);

    const fsmStatus = user?.memberships?.find((el: any) => el.account_id === user.active_account_id)?.account?.features
        ?.fsm;

    return (
        <div className="flex flex-col flex-1 overflow-hidden">
            <div className="fixed top-10">
                <Breadcrumb items={breadcrumbItems} />
            </div>

            <div
                className={
                    'flex flex-col flex-1 border rounded-10 border-solid border-greyscale-200 p-6 bg-white mt-2 overflow-hidden'
                }
            >
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <div className="font-bold text-lg text-greyscale-900">Services</div>
                        <div className="text-sx text-greyscale-900 font-normal">
                            Some Desc. comes here perfectly. It has must to be here.
                        </div>
                    </div>
                    <div className="flex">
                        {fsmStatus && (
                            <Button
                                onClick={() => navigate('/settings/match-with-fsm')}
                                icon={<EqualLogo />}
                                className="mr-4"
                            >
                                Match with FSM
                            </Button>
                        )}
                        <Button onClick={handleSaveClick} type="primary" icon={<SaveLogo />}></Button>
                    </div>
                </div>
                <div className="border border-solid border-t-0 border-greyscale-250 my-6" />
                <Row className="flex flex-1" gutter={[24, 24]}>
                    {/* trades */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col h-full border rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">Trades</div>
                                <div
                                    onClick={handleAllTradesChange}
                                    className="font-normal text-xs text-primary underline cursor-pointer"
                                >
                                    {data.filter((trade: any) => trade.active).length > 0
                                        ? 'Unselect All'
                                        : 'Select All'}
                                </div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 p-6 overflow-scroll">
                                {data.map((trade: any) => (
                                    <div
                                        key={trade.id}
                                        className="flex items-center justify-between border rounded-10 border-solid border-greyscale-250 mb-6 px-6 py-5 "
                                    >
                                        <div className="flex items-center">
                                            <Checkbox
                                                onChange={() => handleTradeChange(trade.id)}
                                                checked={trade.active}
                                                className="mr-3"
                                            />
                                            <div className="text-greyscale-900 text-sm font-normal">{trade.title}</div>
                                        </div>
                                        <div className="text-greyscale-400 font-normal text-2xs">
                                            {trade.activeCount} / {trade.totalCount}{' '}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Col>
                    {/* Equipment Types */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col border h-full rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">Equipment Type</div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 overflow-scroll p-6">
                                {data
                                    ?.filter((item: any) => item.active)
                                    .map((trade: any) => (
                                        <div key={trade.id}>
                                            <div className="mb-3 text-greyscale-900 font-bold text-xs">
                                                {trade.title}
                                            </div>
                                            {trade.children.map((equipmentType: any) => (
                                                <div
                                                    key={equipmentType.id}
                                                    className="flex items-center justify-between border rounded-10 border-solid border-greyscale-250 mb-6 px-6 py-5 "
                                                >
                                                    <div className="flex items-center">
                                                        <Checkbox
                                                            onChange={() =>
                                                                handleEquipmentTypeChange(equipmentType.id, trade.id)
                                                            }
                                                            checked={equipmentType.active}
                                                            className="mr-3"
                                                        />
                                                        <div className="text-greyscale-900 text-sm font-normal">
                                                            {equipmentType.title}
                                                        </div>
                                                    </div>
                                                    <div className="text-greyscale-400 font-normal text-2xs">
                                                        {equipmentType?.activeCount} / {equipmentType?.totalCount}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Col>
                    {/* service types */}
                    <Col sm={24} lg={8}>
                        <div className="flex flex-col h-full border rounded-10 border-solid border-greyscale-250">
                            <div className="flex justify-between items-center p-6">
                                <div className="font-bold text-xs text-greyscale-900 uppercase">SERVICE TYPE</div>
                            </div>
                            <div className="border border-solid border-t-0 border-greyscale-250" />
                            <div className="flex flex-col flex-1 shrink-1 basis-0 overflow-scroll text-sx text-greyscale-900 font-normal p-6">
                                <Tree
                                    switcherIcon={(val: any) => {
                                        if (val.expanded) {
                                            return <CircleDownLogo />;
                                        }
                                        return <CircleUpLogo />;
                                    }}
                                    onCheck={(checkedKeys: any, e: any) => {
                                        handleServiceTypeChange(checkedKeys, e);
                                    }}
                                    checkedKeys={getActiveServiceTypeKeys()}
                                    checkable
                                    selectable={false}
                                    treeData={data.filter((trade: any) => trade.active)}
                                    defaultExpandAll
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Spinner visible={isLoading} />
        </div>
    );
};

const breadcrumbItems = [
    {
        label: 'General Settings',
        href: '#',
        disabled: true,
    },
    {
        label: 'Services',
        href: '/settings/services',
    },
];

export default Services;
