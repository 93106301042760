import { apiURL, axiosClient } from 'service';
import { setSession } from 'utils/jwt';
import notify from 'utils/notification';

import { SIGN_IN_USER_SUCCESS, SIGN_IN_ERROR, IS_LOADING, SET_USER, REMOVE_USER } from 'context/auth/keys';

export const signIn = (dispatch: any) => async (formData: any) => {
    try {
        dispatch({ type: IS_LOADING, payload: true });

        const response = await axiosClient.post(apiURL.signIn, formData);
        const { token } = response?.data || {};

        setSession(token);
        dispatch({ type: SIGN_IN_USER_SUCCESS, payload: response?.data });
        notify({ type: 'success', message: 'You have successfully logged in.' });
        window.location.href = '/conversations';
    } catch (error) {
        dispatch({ type: SIGN_IN_ERROR, payload: error });
        notify({ type: 'error', message: error?.toString() });
        console.error(error);
    }
};

export const getUser = (dispatch: any) => async (user: any) => {
    if (user?.id) {
        try {
            const res = await axiosClient.get(apiURL.getUser + user.id);
            const data = res.data;
            dispatch({ type: SET_USER, payload: data });
        } catch (error) {
            console.error(error);
            dispatch({ type: REMOVE_USER });
        }
    }
};
