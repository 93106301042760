import React, { useEffect, useState } from 'react';

// components
import { Button, Input, Modal, Col, Row, Form, notification } from 'antd';
import TimePicker from 'components/time-picker';

// helpers
import { axiosClient, apiURL } from 'service';
import dayjs from 'dayjs';

// assets
import { DeleteLogo, SaveLogo } from 'assets/svg';

interface IProps {
    arrivalWindow?: any;
    visible: boolean;
    onClose: () => void;
    onSave: () => void;
}

const EditArrivalWindow: React.FC<IProps> = ({ arrivalWindow, visible, onSave, onClose }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (arrivalWindow?.id) {
            form.setFieldsValue({
                title: arrivalWindow.title,
                from_hour: {
                    value: arrivalWindow.from_hour,
                    label: dayjs(arrivalWindow.from_hour, 'HH:mm').format('hh:mm A'),
                },
                to_hour: {
                    value: arrivalWindow.to_hour,
                    label: dayjs(arrivalWindow.to_hour, 'HH:mm').format('hh:mm A'),
                },
            });
        } else {
            form.resetFields();
        }
    }, [arrivalWindow?.id, form]);

    const handleSaveClick = async (values: any) => {
        setLoading(true);
        const payload = {
            arrival_window: {
                title: values.title,
                from_hour: values.from_hour.value,
                to_hour: values.to_hour.value,
            },
        };

        try {
            if (arrivalWindow?.id) {
                await axiosClient.put(`${apiURL.arrivalWindows}/${arrivalWindow.id}`, payload);
            } else {
                await axiosClient.post(apiURL.arrivalWindows, payload);
            }
            notification.success({
                message: 'Success',
                description: 'Arrival window saved successfully!',
            });
            if (onSave) {
                onSave();
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error?.toString() || 'Failed to save arrival window. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteClick = async () => {
        if (!arrivalWindow?.id) {
            return;
        }
        setLoading(true);
        try {
            await axiosClient.delete(`${apiURL.arrivalWindows}/${arrivalWindow.id}`);
            notification.success({
                message: 'Success',
                description: 'Arrival window deleted successfully!',
            });
            if (onSave) {
                onSave();
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: error?.toString() || 'Failed to delete arrival window. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleOk = () => {
        form.submit();
    };

    return (
        <Modal
            forceRender
            width={450}
            title={arrivalWindow?.id ? 'Edit Arrival Window' : 'Add New'}
            open={visible}
            onCancel={onClose}
            okText="Save"
            onOk={handleOk}
            footer={[
                arrivalWindow?.id
                    ? [
                          <div key="footer" className="flex justify-between flex-1">
                              <Button
                                  className="flex-1"
                                  danger
                                  key="delete"
                                  loading={loading}
                                  onClick={handleDeleteClick}
                                  icon={<DeleteLogo />}
                              >
                                  Delete
                              </Button>
                              <div className="w-6" />
                              <Button
                                  className="flex-1"
                                  key="submit"
                                  type="primary"
                                  loading={loading}
                                  onClick={handleOk}
                                  icon={<SaveLogo />}
                              >
                                  Submit
                              </Button>
                          </div>,
                      ]
                    : [
                          <div key="footer" className="flex flex-1">
                              <Button
                                  className="flex-1"
                                  key="submit"
                                  type="primary"
                                  loading={loading}
                                  onClick={handleOk}
                              >
                                  Add new
                              </Button>
                          </div>,
                      ],
            ]}
        >
            <Form layout="vertical" form={form} onFinish={handleSaveClick}>
                <Col span={24}>
                    <Form.Item label="Title" name="title" rules={[{ required: true, message: 'Please add a title!' }]}>
                        <Input placeholder="Title" />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                className="mb-0"
                                label="Starting Time"
                                name="from_hour"
                                rules={[{ required: true, message: 'Please select starting time!' }]}
                            >
                                <TimePicker
                                    onChange={(time) => {
                                        // valuw e should be 15:00 or 15:30 etc.
                                        form.setFieldsValue({
                                            from_hour: {
                                                value: dayjs(time.value).format('HH:mm'),
                                                label: time.label,
                                            },
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                className="mb-0"
                                label="Ending Time"
                                name="to_hour"
                                rules={[{ required: true, message: 'Please select ending time!' }]}
                            >
                                <TimePicker
                                    onChange={(time) => {
                                        // valuw e should be 15:00 or 15:30 etc.
                                        form.setFieldsValue({
                                            to_hour: {
                                                value: dayjs(time.value).format('HH:mm'),
                                                label: time.label,
                                            },
                                        });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Form>
        </Modal>
    );
};

export default EditArrivalWindow;
